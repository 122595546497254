import { useState, useEffect } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import {
  sceneSelector,
  appSelector,
  userSelector,
  readSceneAction,
  ParentPartiesAPIs,
} from "../../core";
import { Grid, ListItem, List } from "@material-ui/core";
import AGGrid from "../Grid";
import { NavLink, useLocation } from "react-router-dom";
import moment from "moment";
import GuardianInfoDialog from "./components/GuardianInfoDialog";
import { CircleLoader } from "react-spinners";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function dateComparator(date1, date2) {
  if (date1 === null && date2 === null) {
    return 0;
  }
  if (date1 === null) {
    return -1;
  }
  if (date2 === null) {
    return 1;
  }

  return date1 - date2;
}

function SchoolGuardians(props) {
  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [guardians, setGuardians] = useState([]);
  const [guardianModal, setGuardianModal] = useState(false);
  const [guardianInfo, setGuardianInfo] = useState(null);
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);

  useEffect(() => {
    async function getSchoolGuardians(schoolId) {
      const allGuardians = await ParentPartiesAPIs.readSchoolGuardians(
        schoolId
      );
      const arrayOfGuardians = allGuardians
        ? Object.values(allGuardians).filter(
            (guardian) => guardian.onboardingComplete
          )
        : [];
      setGuardians(arrayOfGuardians);
      setInitialLoad(false);
    }

    if (props.scene) {
      if (props.scene.id) {
        getSchoolGuardians(props.scene.id);
      }
    }
  }, [props.scene]);

  const handleUpdateGuardianNotes = (notes, guardianId) => {
    const guardianIndex = guardians.findIndex(
      (guardian) => guardian.uid === guardianId
    );
    if (guardianIndex !== -1) {
      const newGuardians = update(guardians, {
        [guardianIndex]: {
          notes: {
            $set: notes,
          },
        },
      });

      setGuardians(newGuardians);
    }
  };

  const handleGuardianOpen = async (guardianInfo) => {
    setGuardianModal(true);
    setGuardianInfo(guardianInfo);
    setLoadingAnalytics(true);

    /* Pull analytics */
    const result = await ParentPartiesAPIs.guardianAnalytics(guardianInfo.uid);
    if (result) {
      setGuardianInfo({
        ...guardianInfo,
        lastActivity: result.lastActivity,
        activityFeed: result.activityFeed,
        sessions: result.sessions,
      });
    }
    setLoadingAnalytics(false);
  };

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                Guardians
              </div>
            </div>
          </Grid>
          <Grid item md={12} xl={12}>
            {mobile ? null : (
              <List
                component="div"
                className="nav-line-alt nav-line d-flex align-items-center mt-3"
              >
                <ListItem button selected className="pr-2">
                  <span className="font-size-sm">{"Table"}</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  className="px-2"
                  component={NavLink}
                  to={`/guardians-leaderboard/${sceneType}/${sceneId}`}
                >
                  <span className="font-size-sm">Leaderboard</span>
                  <div className="divider" />
                </ListItem>
              </List>
            )}
          </Grid>
          <Grid item md={12}>
            <AGGrid
              smallTitle={true}
              titleGrid={"Guardians"}
              search={true}
              statusBar={true}
              exportButton={true}
              exportType={"guardians"}
              rowData={guardians}
              columnDefs={[
                {
                  headerName: "Username",
                  field: "username",
                  sortable: true,
                  filter: true,
                  cellRendererFramework: (params) => (
                    <div
                      onClick={() => {
                        handleGuardianOpen(params.data);
                      }}
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      {`${
                        params.data.username
                          ? params.data.username
                          : "Not Available"
                      }`}
                    </div>
                  ),
                },
                {
                  headerName: "First Name",
                  sortable: true,
                  filter: true,
                  valueGetter: function (params) {
                    if (params.data.firstName) {
                      return `${params.data.firstName}`;
                    } else {
                      return "";
                    }
                  },
                },
                {
                  headerName: "Last Name",
                  sortable: true,
                  filter: true,
                  valueGetter: function (params) {
                    if (params.data.lastName) {
                      return `${params.data.lastName}`;
                    } else {
                      return "";
                    }
                  },
                },
                {
                  headerName: "Children",
                  field: "children",
                  sortable: true,
                  valueFormatter: function (params) {
                    if (params.data.children) {
                      let fix = params.data.children.filter((i) => i.name);
                      if (fix.length) {
                        return `${fix
                          .map((child) => `${child.name}`)
                          .join(", ")}`;
                      } else {
                        return "";
                      }
                    } else {
                      return "";
                    }
                  },
                },
                {
                  headerName: "Grade Levels",
                  colId: "gradeLevels",
                  field: "children",
                  sortable: true,
                  valueFormatter: function (params) {
                    if (params.data.children) {
                      let fix = params.data.children.filter((i) => i.name);
                      if (fix.length) {
                        return `${fix
                          .map((child) => `${child.gradeLevel}`)
                          .join(", ")}`;
                      } else {
                        return "";
                      }
                    } else {
                      return "";
                    }
                  },
                },
                {
                  headerName: "Last session",
                  field: "lastSession",
                  sortable: true,
                  filter: "agDateColumnFilter",
                  valueFormatter: function (params) {
                    if (params.data.lastSession) {
                      return moment(params.data.lastSession).format("L");
                    } else {
                      return null;
                    }
                  },
                  comparator: dateComparator,
                },
                {
                  headerName: "Created on",
                  field: "metadata.creationTime",
                  sortable: true,
                  filter: "agDateColumnFilter",
                  valueFormatter: function (params) {
                    if (params.data.metadata.creationTime) {
                      return moment(params.data.metadata.creationTime).format(
                        "L"
                      );
                    } else {
                      return null;
                    }
                  },
                  comparator: dateComparator,
                },
                {
                  headerName: "Notes",
                  field: "notes",
                  hide: true,
                },
              ]}
            />
          </Grid>
        </Grid>
        {/* Guardian Modal */}
        <GuardianInfoDialog
          guardianInfo={guardianInfo}
          guardianModal={guardianModal}
          loadingAnalytics={loadingAnalytics}
          setGuardianModal={setGuardianModal}
          handleUpdateGuardianNotes={handleUpdateGuardianNotes}
        />
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  readSceneAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolGuardians);
