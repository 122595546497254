import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import {
  sceneSelector,
  appSelector,
  userSelector,
  updateSchoolAction,
  School,
} from "../../core";
import {
  Grid,
  Card,
  List,
  ListItem,
  // Divider,
  TextField,
  Button,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import moment from "moment";
import { CircleLoader } from "react-spinners";

function MoreInfo(props) {
  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  // /* Hours Fun */
  // const [hoursOfOperation, setHoursOfOperation] = useState([]);
  // const [switchBool, setSwitchBool] = useState({
  //   Monday: true,
  //   Tuesday: true,
  //   Wednesday: true,
  //   Thursday: true,
  //   Friday: true,
  //   Saturday: true,
  //   Sunday: true,
  // });

  // const [pocName, setPocName] = useState("");
  // const [pocEmail, setPocEmail] = useState("");

  const [initialLoad, setInitialLoad] = useState(true);
  const [feedbackEmail, setFeedbackEmail] = useState("");

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("success");

  useEffect(() => {
    if (props.scene) {
      if (props.scene.data && props.scene.type === "school") {
        // /* Hours */
        // const hoursOfOperationScene = props.scene.data.hoursOfOperation
        //   ? props.scene.data.hoursOfOperation.weekdayHours
        //   : [];
        // if (hoursOfOperationScene) {
        //   const weekDayHours = hoursOfOperationScene.map((hourObj, index) => ({
        //     ...hourObj,
        //     open:
        //       hourObj.open && hourObj.close
        //         ? hourObj.open
        //         : {
        //             ...hourObj.open,
        //             time: "24 hours",
        //             changed: true,
        //           },
        //     id: `${hourObj.open.day}${Math.random()}`,
        //   }));
        //   setSwitchBool({
        //     Monday:
        //       hoursOfOperationScene.findIndex(
        //         (hourObj) =>
        //           hourObj.open.day === 1 ||
        //           (hourObj.close ? hourObj.close.day === 1 : false)
        //       ) > -1
        //         ? true
        //         : false,
        //     Tuesday:
        //       hoursOfOperationScene.findIndex(
        //         (hourObj) =>
        //           hourObj.open.day === 2 ||
        //           (hourObj.close ? hourObj.close.day === 2 : false)
        //       ) > -1
        //         ? true
        //         : false,
        //     Wednesday:
        //       hoursOfOperationScene.findIndex(
        //         (hourObj) =>
        //           hourObj.open.day === 3 ||
        //           (hourObj.close ? hourObj.close.day === 3 : false)
        //       ) > -1
        //         ? true
        //         : false,
        //     Thursday:
        //       hoursOfOperationScene.findIndex(
        //         (hourObj) =>
        //           hourObj.open.day === 4 ||
        //           (hourObj.close ? hourObj.close.day === 4 : false)
        //       ) > -1
        //         ? true
        //         : false,
        //     Friday:
        //       hoursOfOperationScene.findIndex(
        //         (hourObj) =>
        //           hourObj.open.day === 5 ||
        //           (hourObj.close ? hourObj.close.day === 5 : false)
        //       ) > -1
        //         ? true
        //         : false,
        //     Saturday:
        //       hoursOfOperationScene.findIndex(
        //         (hourObj) =>
        //           hourObj.open.day === 6 ||
        //           (hourObj.close ? hourObj.close.day === 6 : false)
        //       ) > -1
        //         ? true
        //         : false,
        //     Sunday:
        //       hoursOfOperationScene.findIndex(
        //         (hourObj) =>
        //           hourObj.open.day === 0 ||
        //           (hourObj.close ? hourObj.close.day === 0 : false)
        //       ) > -1
        //         ? true
        //         : false,
        //   });
        //   setHoursOfOperation(weekDayHours);
        // } else {
        //   setSwitchBool({
        //     Monday: false,
        //     Tuesday: false,
        //     Wednesday: false,
        //     Thursday: false,
        //     Friday: false,
        //     Saturday: false,
        //     Sunday: false,
        //   });
        // }

        // if (props.scene.data.pocName) {
        //   setPocName(props.scene.data.pocName);
        // }
        // if (props.scene.data.pocEmail) {
        //   setPocEmail(props.scene.data.pocEmail);
        // }
        if (props.scene.data.feedbackEmail) {
          setFeedbackEmail(props.scene.data.feedbackEmail);
        }

        setInitialLoad(false);
      }
    }
  }, [props.scene]);

  const handleSave = () => {
    /* Handle invalid dates */
    // const validDates = hoursOfOperation.filter((hourObj) => {
    //   if (hourObj.open.time) {
    //     if (hourObj.open.time === "24 hours") {
    //       if (typeof hourObj.open.day === "number") {
    //         switch (hourObj.open.day) {
    //           case 0:
    //             return switchBool["Sunday"];
    //           case 1:
    //             return switchBool["Monday"];
    //           case 2:
    //             return switchBool["Tuesday"];
    //           case 3:
    //             return switchBool["Wednesday"];
    //           case 4:
    //             return switchBool["Thursday"];
    //           case 5:
    //             return switchBool["Friday"];
    //           case 6:
    //             return switchBool["Saturday"];
    //           default:
    //             return false;
    //         }
    //       }
    //     }
    //     if (
    //       !hourObj.open.changed && hourObj.close ? !hourObj.close.changed : true
    //     ) {
    //       if (typeof hourObj.open.day === "number") {
    //         switch (hourObj.open.day) {
    //           case 0:
    //             return switchBool["Sunday"];
    //           case 1:
    //             return switchBool["Monday"];
    //           case 2:
    //             return switchBool["Tuesday"];
    //           case 3:
    //             return switchBool["Wednesday"];
    //           case 4:
    //             return switchBool["Thursday"];
    //           case 5:
    //             return switchBool["Friday"];
    //           case 6:
    //             return switchBool["Saturday"];
    //           default:
    //             return false;
    //         }
    //       }
    //     }
    //     if (hourObj.open.time !== "24 hours" && hourObj.close) {
    //       if (
    //         (hourObj.open.changed
    //           ? moment(hourObj.open.time, "LT").isValid()
    //           : true) &&
    //         (hourObj.close.changed
    //           ? moment(hourObj.close.time, "LT").isValid()
    //           : true)
    //       ) {
    //         if (typeof hourObj.open.day === "number") {
    //           switch (hourObj.open.day) {
    //             case 0:
    //               return switchBool["Sunday"];
    //             case 1:
    //               return switchBool["Monday"];
    //             case 2:
    //               return switchBool["Tuesday"];
    //             case 3:
    //               return switchBool["Wednesday"];
    //             case 4:
    //               return switchBool["Thursday"];
    //             case 5:
    //               return switchBool["Friday"];
    //             case 6:
    //               return switchBool["Saturday"];
    //             default:
    //               return false;
    //           }
    //         }
    //       }
    //     }
    //   }
    //   return false;
    // });

    /* Change dates back to google format */
    // const finalDates = validDates.map((hourObj) => {
    //   if (hourObj.open.time === "24 hours") {
    //     return {
    //       id: `${hourObj.open.day}${Math.random()}`,
    //       open: {
    //         day: hourObj.open.day,
    //         time: "0800",
    //       },
    //     };
    //   }
    //   if (
    //     hourObj.open.changed ||
    //     hourObj.close !== undefined ||
    //     (hourObj.close !== null ? hourObj.close.changed : true)
    //   ) {
    //     return {
    //       id: `${hourObj.open.day}${Math.random()}`,
    //       open: {
    //         day: hourObj.open.day,
    //         time: hourObj.open.changed
    //           ? `${moment(hourObj.open.time, "LT")
    //               .format("HH:mm")
    //               .replace(":", "")}`
    //           : hourObj.open.time,
    //       },
    //       close: {
    //         day: hourObj.close.day,
    //         time: hourObj.close.changed
    //           ? `${moment(hourObj.close.time, "LT")
    //               .format("HH:mm")
    //               .replace(":", "")}`
    //           : hourObj.close.time,
    //       },
    //     };
    //   }
    //   return {
    //     id: `${hourObj.open.day}${Math.random()}`,
    //     open: {
    //       day: hourObj.open.day,
    //       time: hourObj.open.time,
    //     },
    //     close: {
    //       day: hourObj.close.day,
    //       time: hourObj.close.time,
    //     },
    //   };
    // });

    /* Handle switch bool */
    // setSwitchBool({
    //   Monday:
    //     finalDates.findIndex(
    //       (hourObj) =>
    //         hourObj.open.day === 1 ||
    //         (hourObj.close ? hourObj.close.day === 1 : false)
    //     ) > -1
    //       ? true
    //       : false,
    //   Tuesday:
    //     finalDates.findIndex(
    //       (hourObj) =>
    //         hourObj.open.day === 2 ||
    //         (hourObj.close ? hourObj.close.day === 2 : false)
    //     ) > -1
    //       ? true
    //       : false,
    //   Wednesday:
    //     finalDates.findIndex(
    //       (hourObj) =>
    //         hourObj.open.day === 3 ||
    //         (hourObj.close ? hourObj.close.day === 3 : false)
    //     ) > -1
    //       ? true
    //       : false,
    //   Thursday:
    //     finalDates.findIndex(
    //       (hourObj) =>
    //         hourObj.open.day === 4 ||
    //         (hourObj.close ? hourObj.close.day === 4 : false)
    //     ) > -1
    //       ? true
    //       : false,
    //   Friday:
    //     finalDates.findIndex(
    //       (hourObj) =>
    //         hourObj.open.day === 5 ||
    //         (hourObj.close ? hourObj.close.day === 5 : false)
    //     ) > -1
    //       ? true
    //       : false,
    //   Saturday:
    //     finalDates.findIndex(
    //       (hourObj) =>
    //         hourObj.open.day === 6 ||
    //         (hourObj.close ? hourObj.close.day === 6 : false)
    //     ) > -1
    //       ? true
    //       : false,
    //   Sunday:
    //     finalDates.findIndex(
    //       (hourObj) =>
    //         hourObj.open.day === 0 ||
    //         (hourObj.close ? hourObj.close.day === 0 : false)
    //     ) > -1
    //       ? true
    //       : false,
    // });
    // setHoursOfOperation(finalDates);

    try {
      const newSchool = new School({
        ...props.scene.data,
        // hoursOfOperation: finalDates
        //   ? {
        //       weekdayHours: finalDates.map(({ open, close }) => ({
        //         open: {
        //           day: open.day,
        //           time: open.time,
        //         },
        //         close: close ? { day: close.day, time: close.time } : null,
        //       })),
        //     }
        //   : null,
        // pocName,
        // pocEmail,
        feedbackEmail,
      });

      props.dispatchUpdateSchool(newSchool);

      setToastMessage(`Changes saved.`);
      setSnackBarType("success");
      setToastOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                Get Started
              </div>
            </div>
          </Grid>
          <Grid item md={12}>
            <List
              component="div"
              className="nav-line-alt nav-line d-flex align-items-center mt-3"
            >
              <ListItem
                button
                component={NavLink}
                to={`/resources/${sceneType}/${sceneId}`}
                className="px-2"
              >
                <span className="font-size-sm">{"Onboard Parents"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem
                button
                className="pr-2"
                component={NavLink}
                to={`/team/${sceneType}/${sceneId}`}
              >
                <span className="font-size-sm">{"Admin Team"}</span>
                <div className="divider" />
              </ListItem>
              <ListItem button selected className="pr-2">
                <span className="font-size-sm">{"Contact Info"}</span>
                <div className="divider" />
              </ListItem>
            </List>
          </Grid>
          <Grid item md={12}>
            <div className="my-4 text-black pt-4">
              Provide an email below to allow parents to send feedback in mobile
              app settings.
            </div>
            <Card
              className="p-4 mt-4"
              style={{
                borderRadius: "0.25rem",
                border: "1px solid rgba(0,0,0,.1)",
              }}
            >
              {/* <div className={"text-black mb-2"} style={{ fontSize: "15px" }}>
              Point of Contact Name
            </div>
            <TextField
              variant={"outlined"}
              fullWidth
              type="text"
              value={pocName}
              onChange={(e) => {
                setPocName(e.target.value);
              }}
            />
            <Divider className="my-4 mb-2" style={{ opacity: 0 }} />
            <div className={"text-black"} style={{ fontSize: "15px" }}>
              Point of Contact Email
            </div>
            <TextField
              variant={"outlined"}
              fullWidth
              type="text"
              value={pocEmail}
              onChange={(e) => {
                setPocEmail(e.target.value);
              }}
            />
            <Divider className="my-4" style={{ opacity: 0 }} /> */}
              <div className={"text-black mb-2"} style={{ fontSize: "15px" }}>
                Guardian Feedback Email
              </div>
              <TextField
                variant={"outlined"}
                fullWidth
                type="text"
                value={feedbackEmail}
                onChange={(e) => {
                  setFeedbackEmail(e.target.value);
                }}
              />
              <div className="mt-4 pt-4">
                <Button
                  variant="contained"
                  size="medium"
                  className="btn-primary shadow-none"
                  onClick={() => handleSave()}
                >
                  <span
                    className="btn-wrapper--label"
                    style={{ fontSize: "14px" }}
                  >
                    Save Changes
                  </span>
                </Button>
              </div>
            </Card>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={toastOpen}
          classes={{
            root:
              snackBarType === "error"
                ? `toastr-danger`
                : snackBarType === "info"
                ? `toastr-primary`
                : `toastr-success`,
          }}
          onClose={() => setToastOpen(false)}
          message={toastMessage}
          autoHideDuration={snackBarType === "success" ? 5000 : null}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setToastOpen(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  dispatchUpdateSchool: updateSchoolAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MoreInfo);
