import { useState, useEffect } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import {
  sceneSelector,
  appSelector,
  userSelector,
  readSceneAction,
  ParentPartiesAPIs,
} from "../../core";
import moment from "moment";
import GuardianInfoDialog from "./components/GuardianInfoDialog";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
} from "@material-ui/core";
import { DayPickerRangeController } from "react-dates";
import { CircleLoader } from "react-spinners";
import AGGrid from "../Grid";

const useStyles = makeStyles((theme) => ({
  noBoxShadow: {
    boxShadow: "none !important",
  },
  selectStyles: {
    fontSize: "14px",
    background: "white",
    "&:focus": {
      backgroundColor: "white !important",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "125px",
    },
  },
  fixInput: {
    borderWidth: "1px !important",
    "& $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&:hover $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
    "&$focused $notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.1) !important",
      borderWidth: "1px !important",
    },
  },
  focused: {},
  notchedOutline: {},
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "4px",
    color: theme.palette.grey[500],
    padding: "2px",
  },
}));

function SchoolGuardiansLeaderboard(props) {
  const classes = useStyles();

  const [leaderboard, setLeaderboard] = useState([]);

  const [timeFrame, setTimeFrame] = useState("August");
  const [initialLoad, setInitialLoad] = useState(true);

  const [dateRangeDialog, setDateRangeDialog] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [dateRangeFocus, setDateRangeFocus] = useState("startDate");

  const [guardianModal, setGuardianModal] = useState(false);
  const [guardianInfo, setGuardianInfo] = useState(null);
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);

  const changeTimeFrame = async (timeFrame) => {
    const sceneId = props.scene ? props.scene.id : null;
    if (!sceneId) {
      return;
    }
    if (timeFrame === "Custom" || timeFrame === "School Year") {
      return;
    }
    // Reset Date Range
    else {
      setDateRangeValue({
        startDate: null,
        endDate: null,
      });
    }
    setTimeFrame(timeFrame);

    const startDate = moment(timeFrame).startOf("month");
    const endDate = moment(timeFrame).endOf("month");

    const result = await ParentPartiesAPIs.fetchLeaderboardStandings(
      sceneId,
      startDate,
      endDate
    );

    setLeaderboard(result);
  };

  const handleCloseDateRange = () => {
    setDateRangeDialog(false);
    setDateRangeValue({
      startDate: null,
      endDate: null,
    });
  };
  const handleDateRangePick = async () => {
    setTimeFrame("Custom");

    const sceneId = props.scene ? props.scene.id : null;
    const startDate = dateRangeValue.startDate
      ? moment(dateRangeValue.startDate).startOf("day")
      : dateRangeValue.endDate;
    const endDate = dateRangeValue.endDate
      ? moment(dateRangeValue.endDate).endOf("day")
      : moment(dateRangeValue.startDate).endOf("day");

    if (startDate === null && endDate === null) {
      return handleCloseDateRange();
    }

    const result = await ParentPartiesAPIs.fetchLeaderboardStandings(
      sceneId,
      startDate,
      endDate
    );

    setLeaderboard(result);
    setDateRangeDialog(false);
  };
  const handleSchoolYearPick = async () => {
    setDateRangeValue({
      startDate: null,
      endDate: null,
    });
    setTimeFrame("School Year");

    const sceneId = props.scene ? props.scene.id : null;
    const startDate = props.app.dateRange
      ? props.app.dateRange[0]
        ? moment(props.app.dateRange[0]).startOf("month")
        : null
      : null;
    const endDate = props.app.dateRange
      ? props.app.dateRange.length
        ? moment(props.app.dateRange[props.app.dateRange.length - 1]).endOf(
            "month"
          )
        : null
      : null;

    const result = await ParentPartiesAPIs.fetchLeaderboardStandings(
      sceneId,
      startDate,
      endDate
    );

    setLeaderboard(result);
  };

  // const handleUpdateGuardianNotes = (notes, guardianId) => {
  //   const guardianIndex = leaderboard.findIndex(
  //     (guardian) => guardian.uid === guardianId
  //   );
  //   if (guardianIndex !== -1) {
  //     const newGuardians = update(leaderboard, {
  //       [guardianIndex]: {
  //         notes: {
  //           $set: notes,
  //         },
  //       },
  //     });

  //     setLeaderboard(newGuardians);
  //   }
  // };

  const handleGuardianOpen = async (guardianInfo) => {
    setGuardianModal(true);
    setGuardianInfo(guardianInfo);
    setLoadingAnalytics(true);

    /* Pull analytics */
    const result = await ParentPartiesAPIs.guardianAnalytics(guardianInfo.uid);
    if (result) {
      setGuardianInfo({
        ...guardianInfo,
        lastActivity: result.lastActivity,
        activityFeed: result.activityFeed,
        sessions: result.sessions,
      });
    }
    setLoadingAnalytics(false);
  };

  useEffect(() => {
    async function fetchData(dateRange, sceneId) {
      try {
        const goal = moment().set({ date: 15 }).valueOf();
        const closestMonth = dateRange.reduce((prev, curr) =>
          Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
        );

        setTimeFrame(closestMonth);

        const startDate = moment(closestMonth).startOf("month");
        const endDate = moment(closestMonth).endOf("month");

        const result = await ParentPartiesAPIs.fetchLeaderboardStandings(
          sceneId,
          startDate,
          endDate
        );
        setLeaderboard(result);

        setInitialLoad(false);
      } catch (err) {
        console.log(err);
      }
    }

    if (props.app.dateRange && props.scene) {
      /* Closest date */
      fetchData(props.app.dateRange, props.scene.id);
    }
  }, [props.app.dateRange, props.scene]);

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                Leaderboard
              </div>
            </div>
          </Grid>
          <Grid item md={12}>
            <AGGrid
              smallTitle={true}
              titleGrid={"Leaderboard"}
              rowData={leaderboard}
              exportButton={true}
              exportType={"leaderboard"}
              columnDefs={[
                {
                  headerName: "Username",
                  field: "userName",
                  sortable: true,
                  filter: true,
                  cellRendererFramework: (params) => (
                    <div
                      onClick={() => {
                        handleGuardianOpen(params.data);
                      }}
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      {`${
                        params.data.userName
                          ? params.data.userName
                          : "Not Available"
                      }`}
                    </div>
                  ),
                },
                {
                  headerName: "Full Name",
                  field: "name",
                  sortable: true,
                  filter: true,
                },
                {
                  headerName: "Points",
                  field: "points",
                  sortable: true,
                  filter: true,
                },
                {
                  headerName: "Children",
                  field: "children",
                  sortable: true,
                  valueFormatter: function (params) {
                    if (params.data.children) {
                      let fix = params.data.children.filter((i) => i.name);
                      if (fix.length) {
                        return `${fix
                          .map((child) => `${child.name}`)
                          .join(", ")}`;
                      } else {
                        return "";
                      }
                    } else {
                      return "";
                    }
                  },
                },
                {
                  headerName: "Grade Levels",
                  colId: "gradeLevels",
                  field: "children",
                  sortable: true,
                  valueFormatter: function (params) {
                    if (params.data.children) {
                      let fix = params.data.children.filter((i) => i.name);
                      if (fix.length) {
                        return `${fix
                          .map((child) => `${child.gradeLevel}`)
                          .join(", ")}`;
                      } else {
                        return "";
                      }
                    } else {
                      return "";
                    }
                  },
                },
              ]}
              customButton={
                props.app.dateRange ? (
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    style={{ boxShadow: "none" }}
                    className="ml-2"
                  >
                    <Select
                      autoWidth={false}
                      value={timeFrame}
                      renderValue={(value) => {
                        return value === "Custom"
                          ? `${
                              dateRangeValue.startDate
                                ? moment(dateRangeValue.startDate).format("ll")
                                : ""
                            }${
                              dateRangeValue.endDate
                                ? `${
                                    dateRangeValue.startDate
                                      ? ` - ${moment(
                                          dateRangeValue.endDate
                                        ).format("ll")}`
                                      : moment(dateRangeValue.endDate).format(
                                          "ll"
                                        )
                                  }`
                                : ""
                            }`
                          : value === "School Year"
                          ? "School Year"
                          : moment(value).format("MMMM YYYY");
                      }}
                      onChange={(e) => {
                        changeTimeFrame(e.target.value);
                      }}
                      classes={{
                        select: classes.selectStyles,
                      }}
                      input={
                        <OutlinedInput
                          classes={{
                            root: classes.fixInput,
                            focused: classes.focused,
                            notchedOutline: classes.notchedOutline,
                          }}
                        />
                      }
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {(props.app.dateRange ? props.app.dateRange : []).map(
                        (date) => (
                          <MenuItem value={date} dense key={date}>
                            {moment(date).format("MMMM YYYY")}
                          </MenuItem>
                        )
                      )}
                      <MenuItem
                        value={"School Year"}
                        dense
                        onClick={() => {
                          handleSchoolYearPick();
                        }}
                      >
                        School Year
                      </MenuItem>
                      <MenuItem
                        value={"Custom"}
                        dense
                        onClick={() => {
                          setDateRangeDialog(true);
                        }}
                      >
                        Custom
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : null
              }
            />
          </Grid>
        </Grid>
        <Dialog
          open={dateRangeDialog}
          onClose={handleCloseDateRange}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              color: "rgb(42, 42, 47)",
              // borderBottom: "none",
            }}
          >
            Select Date Range
          </DialogTitle>
          <DialogContent
            dividers={false}
            className="d-flex flex-row justify-content-center my-4"
          >
            <DayPickerRangeController
              numberOfMonths={2}
              onDatesChange={(dates) => {
                setDateRangeValue({
                  startDate: dates.startDate,
                  endDate: dates.endDate,
                });
              }}
              startDate={dateRangeValue.startDate}
              endDate={dateRangeValue.endDate}
              focusedInput={dateRangeFocus}
              onFocusChange={(focusedInput) => {
                setDateRangeFocus(!focusedInput ? "startDate" : focusedInput);
              }}
              hideKeyboardShortcutsPanel={true}
            />
          </DialogContent>
          <DialogActions className="p-3">
            <Button onClick={handleCloseDateRange}>Cancel</Button>
            <Button
              className="btn-primary"
              onClick={handleDateRangePick}
              autoFocus
            >
              Finish
            </Button>
          </DialogActions>
        </Dialog>
        {/* Guardian Modal */}
        <GuardianInfoDialog
          guardianInfo={guardianInfo}
          guardianModal={guardianModal}
          loadingAnalytics={loadingAnalytics}
          setGuardianModal={setGuardianModal}
          // handleUpdateGuardianNotes={handleUpdateGuardianNotes}
        />
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  readSceneAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolGuardiansLeaderboard);
