import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Grid,
  ListItem,
  List,
  Dialog,
  Typography,
  IconButton,
  DialogContent,
  InputLabel,
  Avatar,
  Divider,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  TextField,
  LinearProgress,
} from "@material-ui/core";
import { CircleLoader } from "react-spinners";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const renderDialogContent = (section, guardianInfo) => {
  switch (section) {
    case 0:
      return (
        <DialogContent
          dividers={false}
          className="pb-4 pt-4"
          style={{ height: 600 }}
        >
          <Grid container spacing={4} justify="center">
            <Grid item md={6}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-1"
              >
                First name
              </InputLabel>
              <Typography
                style={{
                  color: "#000",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {guardianInfo ? guardianInfo.firstName : ""}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-1"
              >
                Last name
              </InputLabel>
              <Typography
                style={{
                  color: "#000",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {guardianInfo ? guardianInfo.lastName : ""}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-1"
              >
                School names
              </InputLabel>
              <Typography
                style={{
                  color: "#000",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {guardianInfo ? guardianInfo.schoolNames : ""}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-1"
              >
                Email
              </InputLabel>
              <Typography
                style={{
                  color: "#000",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {guardianInfo ? guardianInfo.email : ""}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-1"
              >
                Ethnicity
              </InputLabel>
              <Typography
                style={{
                  color: "#000",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {guardianInfo
                  ? guardianInfo.ethnicity
                    ? guardianInfo.ethnicity
                    : "N/A"
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Divider className="my-4" />
          <Grid container spacing={4} justify="left">
            <Grid item md={6}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-1"
              >
                Grade levels
              </InputLabel>
              <Typography
                style={{
                  color: "#000",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {guardianInfo
                  ? guardianInfo.gradeLevels
                    ? guardianInfo.gradeLevels
                        .map((z) => z.grades.join(", "))
                        .flat()
                        .join(", ")
                    : "N/A"
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Divider className="my-4" />
          <Grid container spacing={4} justify="center">
            <Grid item md={6}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-1"
              >
                Points
              </InputLabel>
              <Typography
                style={{
                  color: "#000",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {guardianInfo
                  ? guardianInfo.points
                    ? guardianInfo.points
                    : 0
                  : 0}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-1"
              >
                Last activity
              </InputLabel>
              <Typography
                style={{
                  color: "#000",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {guardianInfo
                  ? guardianInfo.lastActivity
                    ? guardianInfo.lastActivity
                    : ""
                  : ""}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-1"
              >
                Sessions
              </InputLabel>
              <Typography
                style={{
                  color: "#000",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {guardianInfo
                  ? guardianInfo.sessions
                    ? guardianInfo.sessions
                    : ""
                  : ""}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <InputLabel
                style={{ fontSize: "12px", color: "rgba(0,0,0,.6)" }}
                className="mb-1"
              >
                Last session
              </InputLabel>
              <Typography
                style={{
                  color: "#000",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {guardianInfo
                  ? guardianInfo.lastSession
                    ? moment(guardianInfo.lastSession).format("LLL")
                    : "N/A"
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      );
    case 1:
      return (
        <DialogContent
          dividers={false}
          className="pb-4 pt-4"
          style={{ height: 600 }}
        >
          <Grid container spacing={4} justify="center">
            <Grid item md={12}>
              {guardianInfo
                ? guardianInfo.activityFeed
                  ? guardianInfo.activityFeed.length
                    ? guardianInfo.activityFeed.map((dateItem, index) => (
                        <Grid
                          container
                          className={
                            index === 0
                              ? "d-flex flex-row align-items-center"
                              : "d-flex flex-row align-items-center mt-4 pt-2"
                          }
                        >
                          <Grid item md={12} className="mb-4">
                            <div className="d-flex flex-row align-items-center">
                              <div className="mr-3">
                                <FontAwesomeIcon
                                  icon={["far", "calendar-alt"]}
                                  color="#000"
                                />
                              </div>
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  color: "#000",
                                }}
                              >
                                {dateItem.day}
                              </Typography>
                            </div>
                          </Grid>
                          {dateItem.events.map((event, index) => (
                            <Grid item md={12}>
                              <div className="d-flex flex-row align-items-center">
                                <Typography
                                  style={{
                                    color: "rgb(143, 143, 145)",
                                    fontSize: "14px",
                                    width: 100,
                                  }}
                                  className="mr-2 py-3"
                                >
                                  {event.date}
                                </Typography>
                                <div
                                  style={
                                    index === 0
                                      ? {
                                          borderTop: "1px solid #d3d3d3",
                                          borderBottom: "1px solid #d3d3d3",
                                        }
                                      : { borderBottom: "1px solid #d3d3d3" }
                                  }
                                  className="py-3 w-100"
                                >
                                  <Typography
                                    style={{
                                      color: "#000",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {event.name || "Undefined"}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      ))
                    : null
                  : null
                : null}
            </Grid>
          </Grid>
        </DialogContent>
      );
    case 2:
      return (
        <DialogContent
          dividers={false}
          className="pb-4 pt-4"
          style={{ height: 600 }}
        >
          <Grid container>
            <Grid item md={12}>
              {guardianInfo
                ? guardianInfo.completedScorecards
                  ? Object.values(guardianInfo.completedScorecards)
                      .sort((a, b) => b.start - a.start)
                      .map((scorecard, index) => (
                        <Card
                          style={
                            index === 0
                              ? {
                                  marginTop: 10,
                                  border: "1px solid rgb(233 232 232)",
                                }
                              : {
                                  marginTop: 20,
                                  border: "1px solid rgb(233 232 232)",
                                }
                          }
                          key={scorecard.id}
                        >
                          <CardContent>
                            <div style={{ fontSize: 14, color: "#000" }}>
                              {`Weekly Scorecard (${moment(scorecard.start)
                                .startOf("isoWeek")
                                .format("MMM Do")} - ${moment(scorecard.end)
                                .endOf("isoWeek")
                                .format("MMM Do")})`}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: 10,
                              }}
                            >
                              {scorecard.response.map((question) => (
                                <FormControlLabel
                                  style={{ cursor: "default" }}
                                  control={
                                    <Checkbox
                                      checked={question.answer}
                                      size={"small"}
                                    />
                                  }
                                  label={
                                    <div
                                      style={{ fontSize: 14, color: "#000" }}
                                    >
                                      {question.question}
                                    </div>
                                  }
                                />
                              ))}
                            </div>
                          </CardContent>
                        </Card>
                      ))
                  : null
                : null}
            </Grid>
          </Grid>
        </DialogContent>
      );
    case 3:
      return (
        <DialogContent
          dividers={false}
          className="pb-4 pt-4"
          style={{ height: 600 }}
        >
          <Grid container>
            <Grid item md={12}>
              {guardianInfo
                ? guardianInfo.completedEvaluations
                  ? Object.values(guardianInfo.completedEvaluations)
                      .sort((a, b) => b.start - a.start)
                      .map((scorecard, index) => (
                        <Card
                          style={
                            index === 0
                              ? {
                                  marginTop: 10,
                                  border: "1px solid rgb(233 232 232)",
                                }
                              : {
                                  marginTop: 20,
                                  border: "1px solid rgb(233 232 232)",
                                }
                          }
                          key={scorecard.id}
                        >
                          <CardContent className="mb-4">
                            <div style={{ fontSize: 14, color: "#000" }}>
                              {`Parent Evaluation - ${moment(
                                scorecard.start
                              ).format("MMMM YYYY")}`}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: 10,
                              }}
                            >
                              {scorecard.response.responses.map((question) => {
                                const percent = (question.answer / 5) * 100;
                                return (
                                  <ListItem
                                    className="pt-3 pb-3 d-block"
                                    key={index}
                                  >
                                    <div className="align-box-row py-1">
                                      <div>
                                        <div
                                          className="text-black"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {question.question}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row align-item-center mt-2">
                                      <div
                                        style={{
                                          width: "100%",
                                          marginRight: 16,
                                        }}
                                      >
                                        <LinearProgress
                                          variant="determinate"
                                          className={`${
                                            percent <= 40
                                              ? "progress-bar-danger"
                                              : percent > 40 && percent < 70
                                              ? "progress-bar-warning"
                                              : "progress-bar-success"
                                          } w-100`}
                                          value={percent}
                                          style={{ height: ".5rem" }}
                                        />
                                        <div className="d-flex flex-row justify-content-between">
                                          <div className="text-black-50 font-size-sm">
                                            Strongly Disagree
                                          </div>
                                          <div className="text-black-50 font-size-sm">
                                            Strongly Agree
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </ListItem>
                                );
                              })}
                              {scorecard.response.topic ? (
                                <div className="mt-4 px-3">
                                  <div
                                    style={{ fontSize: 14, color: "#000" }}
                                    className="mr-2"
                                  >
                                    A topic I would like to see on the Parent
                                    Parties Engage app is:
                                  </div>
                                  <div className="mt-2">
                                    <TextField
                                      value={scorecard.response.topic}
                                      variant="outlined"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      hiddenLabel
                                      size="small"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              ) : null}
                              {scorecard.response.feedback ? (
                                <div className="mt-4 px-3">
                                  <div
                                    style={{ fontSize: 14, color: "#000" }}
                                    className="mr-2"
                                  >
                                    {scorecard.response.feedbackQuestion}
                                  </div>
                                  <div className="mt-2">
                                    <TextField
                                      value={scorecard.response.feedback}
                                      variant="outlined"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      hiddenLabel
                                      size="small"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              ) : null}
                              {scorecard.response.comments ? (
                                <div className="mt-4 px-3">
                                  <div
                                    style={{ fontSize: 14, color: "#000" }}
                                    className="mr-2"
                                  >
                                    Additional Comments:
                                  </div>
                                  <div className="mt-2">
                                    <TextField
                                      value={scorecard.response.comments}
                                      variant="outlined"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      hiddenLabel
                                      size="small"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </CardContent>
                        </Card>
                      ))
                  : null
                : null}
            </Grid>
          </Grid>
        </DialogContent>
      );
    default:
      return null;
  }
};

const GuardianInfoDialog = (props) => {
  const {
    guardianInfo,
    guardianModal,
    setGuardianModal,
    loadingAnalytics,
  } = props;

  const [dialogSection, setDialogSection] = useState(0);

  useEffect(() => {
    setDialogSection(0);
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={guardianModal}
      onClose={() => {
        setGuardianModal(false);
      }}
      classes={{ paper: "modal-content rounded-lg" }}
    >
      <DialogTitle
        onClose={() => {
          setGuardianModal(false);
        }}
        style={{ borderBottom: "none" }}
      >
        <div className="d-flex flex-row align-items-center">
          {guardianInfo ? (
            guardianInfo.avatar ? (
              <Avatar
                src={guardianInfo.avatar}
                style={{ width: 40, height: 40 }}
                className="mr-3"
              />
            ) : null
          ) : null}
          <div className="text-black">
            {guardianInfo
              ? guardianInfo.username
                ? guardianInfo.username
                : "Guardian"
              : "Guardian"}
          </div>
          {loadingAnalytics ? (
            <div className="ml-4">
              <CircleLoader
                className="d-flex align-self-center"
                color={"#18A9A9"}
                loading={true}
                size={20}
              />
            </div>
          ) : null}
        </div>
        <List
          component="div"
          className="nav-line-alt nav-line d-flex align-items-center mt-4"
        >
          <ListItem
            button
            selected={dialogSection === 0}
            className="pr-2"
            onClick={() => setDialogSection(0)}
          >
            <span className="font-size-sm">{"Information"}</span>
            <div className="divider" />
          </ListItem>
          <ListItem
            button
            className="px-2"
            selected={dialogSection === 1}
            onClick={() => setDialogSection(1)}
          >
            <span className="font-size-sm">Activity Feed</span>
            <div className="divider" />
          </ListItem>
          <ListItem
            button
            className="px-2"
            selected={dialogSection === 2}
            onClick={() => setDialogSection(2)}
          >
            <span className="font-size-sm">Weekly Scorecard Responses</span>
            <div className="divider" />
          </ListItem>
          <ListItem
            button
            disableRipple
            className="px-2"
            selected={dialogSection === 3}
            onClick={() => setDialogSection(3)}
          >
            <span className="font-size-sm">Parent Evaluation Responses</span>
            <div className="divider" />
          </ListItem>
        </List>
      </DialogTitle>
      {renderDialogContent(dialogSection, guardianInfo)}
      <div
        style={{ width: "100%", height: "10px", backgroundColor: "#18A9A9" }}
      />
    </Dialog>
  );
};

export default GuardianInfoDialog;
