import { Database, Functions, Storage, Auth } from "./Firebase";
import * as _ from "lodash";
import { readSchoolActivities, readInSchoolActivities } from "./Activity";
import { readCurriculum } from "./School";
import { readDistrict } from "./District";
import Bugsnag from "@bugsnag/js";
import { readSchool } from "./School";
import language_array from "../../../utils/language/language_array";
const moment = require("moment");
const { v4: uuidv4 } = require("uuid");

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const themes = (schedule: any) =>
  schedule === "August"
    ? [
        {
          id: 0,
          name: "Off to a Good Start",
          monthOfYear: 7,
        },
        {
          id: 1,
          name: "Helping with Homework",
          monthOfYear: 8,
        },
        {
          id: 2,
          name: "Conference Conversations",
          monthOfYear: 9,
        },
        {
          id: 3,
          name: "Doctor's Orders",
          monthOfYear: 10,
        },
        {
          id: 4,
          name: "Make Time for Reading Together",
          monthOfYear: 11,
        },
        {
          id: 5,
          name: "Spreading Kindness",
          monthOfYear: 0,
        },
        {
          id: 6,
          name: "Arts in Education",
          monthOfYear: 1,
        },
        {
          id: 7,
          name: "Math Matters",
          monthOfYear: 2,
        },
        {
          id: 8,
          name: "Positive Parenting",
          monthOfYear: 3,
        },
        {
          id: 9,
          name: "Celebrating Parents",
          monthOfYear: 4,
        },
      ]
    : [
        {
          id: 0,
          name: "Off to a Good Start",
          monthOfYear: 8,
        },
        {
          id: 1,
          name: "Helping with Homework",
          monthOfYear: 9,
        },
        {
          id: 2,
          name: "Conference Conversations",
          monthOfYear: 10,
        },
        {
          id: 4,
          name: "Make Time for Reading Together",
          monthOfYear: 11,
        },
        {
          id: 5,
          name: "Spreading Kindness",
          monthOfYear: 0,
        },
        {
          id: 6,
          name: "Arts in Education",
          monthOfYear: 1,
        },
        {
          id: 3,
          name: "Doctor's Orders",
          monthOfYear: 2,
        },
        {
          id: 7,
          name: "Math Matters",
          monthOfYear: 3,
        },
        {
          id: 8,
          name: "Positive Parenting",
          monthOfYear: 4,
        },
        {
          id: 9,
          name: "Celebrating Parents",
          monthOfYear: 5,
        },
      ];

const readFile = async (file: File | Blob): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        if (reader.result instanceof ArrayBuffer) {
          resolve(reader.result);
        } else {
          reject();
        }
      },
      false
    );

    if (file) {
      reader.readAsArrayBuffer(file);
    }
  });

const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};
const getResizedImage = async (
  triesRemaining: number,
  storageRef: any
): Promise<any> => {
  if (triesRemaining < 0) {
    return Promise.reject(new Error("Could not upload file(s)."));
  }
  return storageRef.getDownloadURL().then(
    (downloadUrl: string) => {
      return downloadUrl;
    },
    async (error: { code: any }) => {
      switch (error.code) {
        case "storage/object-not-found":
          await sleep(2000);
          return getResizedImage(triesRemaining - 1, storageRef);
        default:
          return Promise.reject(error);
      }
    }
  );
};
const uploadLogo = async (
  route: string,
  file: File | Blob | any
): Promise<any> => {
  let storageRef = Storage.ref(route);
  let data: Blob | ArrayBuffer;
  if (file instanceof Blob || file._data) {
    data = file;
  } else {
    data = await readFile(file);
  }
  await storageRef.put(data, { contentType: file.type });

  // Change route to 550x550 image path
  const endingToChange = route.substr(
    route.lastIndexOf("/") + 1,
    route.length - 1
  );
  const newEnding = endingToChange + "_550x550";
  const resizedRoute = route.replace(endingToChange, newEnding);

  // Poll for resized image
  const resizedImageUrl = await getResizedImage(10, Storage.ref(resizedRoute));
  return resizedImageUrl;
};

export const uploadSchoolLogo = async (
  schoolId: number | string,
  file: File | Blob | any
): Promise<any> => {
  const currentTime = uuidv4();
  return uploadLogo(`schools/${schoolId}/logo_${currentTime}`, file);
};

// School File Upload
const uploadSchoolBillFile = async (
  route: string,
  file: File | Blob | any
): Promise<any> => {
  let storageRef = Storage.ref(route);
  let data: Blob | ArrayBuffer;
  if (file instanceof Blob || file._data) {
    data = file;
  } else {
    data = await readFile(file);
  }
  const fileUrl = await storageRef
    .put(data, { contentType: file.type })
    .then((snapshot) => {
      return snapshot.ref.getDownloadURL();
    });

  // JPEG or PNG
  if (!["application/pdf"].includes(file.type)) {
    // Change route to _800x800 image path
    const endingToChange = route.substr(
      route.lastIndexOf("/") + 1,
      route.length - 1
    );
    const newEnding = endingToChange + "_800x800";
    const resizedRoute = route.replace(endingToChange, newEnding);

    // Poll for resized image
    const resizedImageUrl = await getResizedImage(
      10,
      Storage.ref(resizedRoute)
    );
    return resizedImageUrl;
  }
  // Video or PDF
  else {
    return fileUrl;
  }
};

export const uploadSchoolBillingFile = async (
  schoolId: number | string,
  file: File | Blob | any
): Promise<any> => {
  const currentTime = uuidv4();
  return uploadSchoolBillFile(
    `schools/${schoolId}/billingFile_${currentTime}`,
    file
  );
};

/// Upload Activity Thumbnail
const uploadThumbnail = async (
  route: string,
  file: File | Blob | any
): Promise<any> => {
  let storageRef = Storage.ref(route);
  let data: Blob | ArrayBuffer;
  if (file instanceof Blob || file._data) {
    data = file;
  } else {
    data = await readFile(file);
  }
  await storageRef.put(data, { contentType: file.type });

  // Change route to _800x800 image path
  const endingToChange = route.substr(
    route.lastIndexOf("/") + 1,
    route.length - 1
  );
  const newEnding = endingToChange + "_800x800";
  const resizedRoute = route.replace(endingToChange, newEnding);

  // Poll for resized image
  const resizedImageUrl = await getResizedImage(10, Storage.ref(resizedRoute));
  return resizedImageUrl;
};

export const uploadActivityThumbnail = async (
  activityId: number | string,
  file: File | Blob | any
): Promise<any> => {
  const currentTime = uuidv4();
  return uploadThumbnail(
    `activities/${activityId}/thumbnail_${currentTime}`,
    file
  );
};

/// Upload Activity Video
const uploadVideo = async (
  route: string,
  file: File | Blob | any
): Promise<any> => {
  let storageRef = Storage.ref(route);
  let data: Blob | ArrayBuffer;
  if (file instanceof Blob || file._data) {
    data = file;
  } else {
    data = await readFile(file);
  }
  const videoUrl = await storageRef
    .put(data, { contentType: file.type })
    .then((snapshot) => {
      return snapshot.ref.getDownloadURL();
    });

  return videoUrl;
};

export const uploadActivityVideo = async (
  activityId: number | string,
  file: File | Blob | any
): Promise<any> => {
  const currentTime = uuidv4();
  return uploadVideo(`activities/${activityId}/video_${currentTime}`, file);
};

export const checkPlaceId = async (placeId: string): Promise<any> => {
  const checkPlaceIdCall = Functions.httpsCallable("checkPlaceId");
  const { data }: { data: any } = await checkPlaceIdCall({
    checkPlaceId: placeId,
  });
  return data;
};

export const checkLoginByEmail = async (email: string) => {
  const userByEmail = await Auth.fetchSignInMethodsForEmail(email);
  if (userByEmail) {
    return userByEmail;
  } else {
    return null;
  }
};

// Guardians
const guardiansRef = Database.ref("guardians");
export const readAllGuardians = async (): Promise<any> => {
  try {
    const guardiansSnapshot = await new Promise<any>((resolve) =>
      guardiansRef
        .orderByChild("onboardingComplete")
        .equalTo(true)
        .once("value", resolve)
    );
    let guardians =
      (guardiansSnapshot.val() as { [guardianId: string]: any }) || {};
    return guardians;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const readSchoolGuardians = async (schoolId): Promise<any> => {
  try {
    let guardians = await Database.ref(`guardians`)
      .orderByChild(`schoolIds/${schoolId}`)
      .equalTo(true)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal;
      });
    return guardians;
  } catch (err) {
    console.error(err);
    return [];
  }
};

// Get Uploads
// Maybe pass schoolIds to map over to more efficent in future
export const readAllUploads = async (current: any): Promise<any> => {
  try {
    if (current) {
      const schoolIds = await Database.ref("schools")
        .once("value")
        .then((snapshot) => {
          const snapshotVal = snapshot.val();
          return snapshotVal ? Object.keys(snapshotVal) : [];
        });
      const uploads = await Promise.all(
        schoolIds.map(async (schoolId) => {
          const schoolUploads = await readSchoolUploads(schoolId, true);
          return schoolUploads;
        })
      );
      return uploads;
    }
    let resources = await Database.ref(`resources`)
      .orderByChild("uploads")
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal;
      });

    return resources;
  } catch (err) {
    console.error(err);
    return [];
  }
};

// Admin Files
const uploadResource = async (
  route: string,
  file: File | Blob | any
): Promise<any> => {
  let storageRef = Storage.ref(route);
  let data: Blob | ArrayBuffer;
  if (file instanceof Blob || file._data) {
    data = file;
  } else {
    data = await readFile(file);
  }
  await storageRef.put(data, { contentType: file.type });

  const url = await storageRef.getDownloadURL();
  return url;
};

export const uploadAdminResourceFile = async (
  resource: any,
  premium?: boolean
): Promise<any> => {
  try {
    const currentTime = moment().valueOf();
    const uuid = uuidv4();
    const route = `resources/allSchools/file_${uuid}`;
    const url = await uploadResource(route, resource.file);

    resource.url = url;
    resource.time = currentTime;
    resource.premium = premium;
    delete resource.file;

    const resourceRef = await Database.ref(`resources`).push(resource);
    (resource as any).id = resourceRef.key;

    await resourceRef.set(resource);

    const resourceSnapshot = await new Promise<any>((resolve) =>
      resourceRef.once("value", resolve)
    );
    const createdResource = resourceSnapshot.val() as any;

    return createdResource;
  } catch (err) {
    console.error(err);
  }
};

export const uploadAdminResourceGeneralFile = async (
  resource: any
): Promise<any> => {
  try {
    const currentTime = moment().valueOf();
    const uuid = uuidv4();
    const route = `resources-general/allSchools/file_${uuid}`;
    const url = await uploadResource(route, resource.file);

    resource.url = url;
    resource.time = currentTime;
    delete resource.file;

    const resourceRef = await Database.ref(`resources-general`).push(resource);
    (resource as any).id = resourceRef.key;

    await resourceRef.set(resource);

    const resourceSnapshot = await new Promise<any>((resolve) =>
      resourceRef.once("value", resolve)
    );
    const createdResource = resourceSnapshot.val() as any;

    return createdResource;
  } catch (err) {
    console.error(err);
  }
};

export const deleteAdminResourceFile = async (
  resourceId: string
): Promise<any> => {
  try {
    await Database.ref(`resources/${resourceId}`).remove();
  } catch (err) {
    console.error(err);
  }
};

export const deleteAdminResourceGeneralFile = async (
  resourceId: string
): Promise<any> => {
  try {
    await Database.ref(`resources-general/${resourceId}`).remove();
  } catch (err) {
    console.error(err);
  }
};

export const readAdminFiles = async (): Promise<any> => {
  try {
    let adminFiles = await Database.ref(`resources`)
      .orderByChild("allSchools")
      .equalTo(true)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal;
      });
    return adminFiles;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const readAdminGeneralFiles = async (): Promise<any> => {
  try {
    let adminFiles = await Database.ref(`resources-general`)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal;
      });
    return adminFiles;
  } catch (err) {
    console.error(err);
    return [];
  }
};

// Upload Onboarding
export const uploadOnboardingVideo = async (
  video: any,
  type: string
): Promise<any> => {
  try {
    const currentTime = uuidv4();
    const route = `appSettings/onboardingVideo_${type}_${currentTime}`;
    const url = await uploadVideo(route, video);

    const onboardingVideoRef = Database.ref(
      `appSettings/onboardingVideo${type}`
    );
    await onboardingVideoRef.set(url);

    const onboardingVideoSnapshot = await new Promise<any>((resolve) =>
      onboardingVideoRef.once("value", resolve)
    );
    const videoUrl = onboardingVideoSnapshot.val() as any;
    return videoUrl;
  } catch (err) {
    console.error(err);
  }
};

export const readAppSettings = async (): Promise<any> => {
  try {
    let appSettings = await Database.ref(`appSettings`)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal;
      });
    return appSettings;
  } catch (err) {
    console.error(err);
    return null;
  }
};

// School Resources
export const readSchoolFiles = async (): Promise<any> => {
  try {
    let adminFiles = await Database.ref(`resources`)
      .orderByChild("allSchools")
      .equalTo(true)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal;
      });
    return adminFiles;
  } catch (err) {
    console.error(err);
    return [];
  }
};

// School Uploads
export const readSchoolUploads = async (
  schoolId: string,
  current?: any
): Promise<any> => {
  if (current) {
    try {
      const startOfMonth = moment().startOf("month").valueOf();
      let schoolUploads = await Database.ref(`resources/${schoolId}/uploads`)
        .orderByChild("time")
        .startAt(startOfMonth)
        .once("value")
        .then(function (snapshot) {
          const snapshotVal = snapshot.val();
          return snapshotVal;
        });
      return schoolUploads;
    } catch (err) {
      console.error(err);
      return [];
    }
  } else {
    try {
      let schoolUploads = await Database.ref(`resources/${schoolId}/uploads`)
        .once("value")
        .then(function (snapshot) {
          const snapshotVal = snapshot.val();
          return snapshotVal;
        });
      return schoolUploads;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
};

export const updateCurrentWeeklyQuestions = async (
  questions: any,
  language: string
) => {
  try {
    const weeklyQuestionsRef = Database.ref(
      `appSettings/weeklyQuestions${language}`
    );
    await weeklyQuestionsRef.set(questions);

    const weeklyQuestionsSnapshot = await new Promise<any>((resolve) =>
      weeklyQuestionsRef.once("value", resolve)
    );
    const currentQuestions = weeklyQuestionsSnapshot.val() as any;
    return currentQuestions;
  } catch (err) {
    console.error(err);
    return [];
  }
};

// Responses
export const readAllResponses = async () => {
  try {
    const responsesRef = Database.ref(`responses`);
    let responses = await responsesRef.once("value").then(function (snapshot) {
      const snapshotVal = snapshot.val();
      return snapshotVal
        ? Object.values(snapshotVal)
            .map((item: any) => Object.values(item))
            .flat()
        : [];
    });
    return responses.filter((response: any) => !response.archived);
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const readSchoolResponses = async (schoolId: string) => {
  try {
    const responsesRef = Database.ref(`responses/${schoolId}`);
    let responses = await responsesRef
      .orderByChild("archived")
      .equalTo(null)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal ? Object.values(snapshotVal) : [];
      });
    return responses;
  } catch (err) {
    console.error(err);
    return [];
  }
};

// Delete Upload
export const deleteUpload = async (upload: any): Promise<void> => {
  const uploadRef = Database.ref(
    `resources/${upload.schoolId}/uploads/${upload.id}`
  );
  uploadRef.remove();
};

// Fetch Date Range
export const fetchDateRange = async () => {
  try {
    const dateRangeRef = Database.ref(`appSettings/dateRange`);
    let dateRange = await dateRangeRef.once("value").then(function (snapshot) {
      const snapshotVal = snapshot.val();
      return snapshotVal;
    });
    return dateRange;
  } catch (err) {
    console.error(err);
    return [];
  }
};

/* DATA HANDLER */
function handleSessionsData(data: any[], dateKey: string, dateType: string) {
  let dates: any[] = [];
  data.map((dataItem) => {
    const time = moment(dataItem[dateKey]).startOf(dateType).format("L");
    const dateIndex = dates.findIndex((date) => date.x === time);
    if (dateIndex > -1) {
      dates[dateIndex] = {
        ...dates[dateIndex],
        y: dates[dateIndex].y + (dataItem.session || 0),
      };
    } else {
      dates.push({
        x: time,
        y: dataItem.session || 0,
      });
    }
    return dataItem;
  });
  return dates.sort((a, b) => (new Date(a.x) as any) - (new Date(b.x) as any));
}
function handlePointsData(data: any[], dateKey: string, dateType: string) {
  let dates: any[] = [];
  data.map((dataItem) => {
    const time = moment(dataItem[dateKey]).startOf(dateType).format("L");
    const dateIndex = dates.findIndex((date) => date.x === time);
    if (dateIndex > -1) {
      dates[dateIndex] = {
        ...dates[dateIndex],
        y: dates[dateIndex].y + (dataItem.points || 0),
      };
    } else {
      dates.push({
        x: time,
        y: dataItem.points || 0,
      });
    }
    return dataItem;
  });
  return dates.sort((a, b) => (new Date(a.x) as any) - (new Date(b.x) as any));
}
function handleActivitiesCompletedData(
  data: any[],
  dateKey: string,
  dateType: string
) {
  let dates: any[] = [];
  data.map((dataItem) => {
    const time = moment(dataItem[dateKey]).startOf(dateType).format("L");
    const dateIndex = dates.findIndex((date) => date.x === time);
    if (dateIndex > -1) {
      dates[dateIndex] = {
        ...dates[dateIndex],
        y:
          dates[dateIndex].y +
          (dataItem.activity0 || 0) +
          (dataItem.activity1 || 0) +
          (dataItem.activity2 || 0) +
          (dataItem.activity3 || 0) +
          (dataItem.activity4 || 0) +
          (dataItem.activity5 || 0) +
          (dataItem.activity6 || 0) +
          (dataItem.activity7 || 0),
      };
    } else {
      dates.push({
        x: time,
        y:
          (dataItem.activity0 || 0) +
          (dataItem.activity1 || 0) +
          (dataItem.activity2 || 0) +
          (dataItem.activity3 || 0) +
          (dataItem.activity4 || 0) +
          (dataItem.activity5 || 0) +
          (dataItem.activity6 || 0) +
          (dataItem.activity7 || 0),
      });
    }
    return dataItem;
  });
  return dates.sort((a, b) => (new Date(a.x) as any) - (new Date(b.x) as any));
}
function handleNewGuardiansEnrolledData(
  data: any[],
  dateKey: string,
  dateType: string
) {
  let dates: any[] = [];
  data.map((dataItem) => {
    const time = moment(dataItem[dateKey]).startOf(dateType).format("L");
    const dateIndex = dates.findIndex((date) => date.x === time);
    if (dateIndex > -1) {
      dates[dateIndex] = {
        ...dates[dateIndex],
        y: dates[dateIndex].y + (dataItem.guardianEnrolled || 0),
      };
    } else {
      dates.push({
        x: time,
        y: dataItem.guardianEnrolled || 0,
      });
    }
    return dataItem;
  });
  return dates.sort((a, b) => (new Date(a.x) as any) - (new Date(b.x) as any));
}
function handleViewsData(data: any[], dateKey: string, dateType: string) {
  let dates: any[] = [];
  data.map((dataItem) => {
    const time = moment(dataItem[dateKey]).startOf(dateType).format("L");
    const dateIndex = dates.findIndex((date) => date.x === time);

    // Add up all activity views for item
    const activityViews = dataItem
      ? Object.keys(dataItem)
          .map((key) => (key.includes("activityView") ? dataItem[key] : 0))
          .reduce((a: any, b: any) => a + b, 0)
      : 0;

    if (dateIndex > -1) {
      dates[dateIndex] = {
        ...dates[dateIndex],
        y: dates[dateIndex].y + (activityViews || 0),
      };
    } else {
      dates.push({
        x: time,
        y: activityViews || 0,
      });
    }
    return dataItem;
  });
  return dates.sort((a, b) => (new Date(a.x) as any) - (new Date(b.x) as any));
}

export const fetchAdminDashboard = async (startDate: any, endDate: any) => {
  try {
    if (!Auth.currentUser) {
      return null;
    }

    /* Handle time frame */
    let startAtDate: number;
    let endAtDate: number | null = null;
    startAtDate = startDate.valueOf();
    endAtDate = endDate.valueOf();

    const totalAnalyticsEventsRef = Database.ref(
      "analytics/allAnalytics/events"
    );
    const adminAnalytics = await totalAnalyticsEventsRef
      .orderByChild("time")
      .startAt(startAtDate)
      .endAt(endAtDate)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val() ? Object.values(snapshot.val()) : [];
        return snapshotVal;
      });

    const sessionsData = adminAnalytics.filter(
      (analytic: any) => analytic.session
    );
    const sessionsValue = sessionsData.reduce(
      (total: any, next: any) => total + (next.session || 0),
      0
    );

    const pointsData = adminAnalytics.filter(
      (analytic: any) => analytic.points
    );
    const pointsValue = pointsData.reduce(
      (total: any, next: any) => total + (next.points || 0),
      0
    );

    const activitiesCompletedData = adminAnalytics.filter(
      (analytic: any) =>
        analytic.activity0 ||
        analytic.activity1 ||
        analytic.activity2 ||
        analytic.activity3 ||
        analytic.activity4 ||
        analytic.activity5 ||
        analytic.activity6 ||
        analytic.activity7
    );
    const activitiesCompletedValue = activitiesCompletedData.reduce(
      (total: any, next: any) =>
        total +
        (next.activity0 || 0) +
        (next.activity1 || 0) +
        (next.activity2 || 0) +
        (next.activity3 || 0) +
        (next.activity4 || 0) +
        (next.activity5 || 0) +
        (next.activity6 || 0) +
        (next.activity7 || 0),
      0
    );

    const newGuardiansEnrolledData = adminAnalytics.filter(
      (analytic: any) => analytic.guardianEnrolled
    );
    const newGuardiansEnrolledValue = newGuardiansEnrolledData.reduce(
      (total: any, next: any) => total + (next.guardianEnrolled || 0),
      0
    );

    const dateType = "day";

    return {
      sessions: {
        value: sessionsValue,
        data: handleSessionsData(sessionsData, "dateLabel", dateType),
        percentDiff: null,
      },
      points: {
        value: pointsValue,
        data: handlePointsData(pointsData, "dateLabel", dateType),
        percentDiff: null,
      },
      activitiesCompleted: {
        value: activitiesCompletedValue,
        data: handleActivitiesCompletedData(
          activitiesCompletedData,
          "dateLabel",
          dateType
        ),
        percentDiff: null,
      },
      newGuardiansEnrolled: {
        value: newGuardiansEnrolledValue,
        data: handleNewGuardiansEnrolledData(
          newGuardiansEnrolledData,
          "dateLabel",
          dateType
        ),
        percentDiff: null,
      },
    };
  } catch (err) {
    return err;
  }
};

export const fetchDistrictDashboard = async (
  schoolIds: any,
  startDate: any,
  endDate: any
) => {
  try {
    if (!Auth.currentUser) {
      return null;
    }

    /* Handle time frame */
    let startAtDate: number;
    let endAtDate: number | null = null;
    startAtDate = startDate.valueOf();
    endAtDate = endDate.valueOf();

    let districtAnalytics: any = [];

    await Promise.all(
      schoolIds.map(async (schoolId) => {
        const schoolAnalyticsEventsRef = Database.ref(
          `analytics/schoolAnalytics/${schoolId}/events`
        );
        const schoolAnalytics = await schoolAnalyticsEventsRef
          .orderByChild("time")
          .startAt(startAtDate)
          .endAt(endAtDate)
          .once("value")
          .then(function (snapshot) {
            const snapshotVal = snapshot.val()
              ? Object.values(snapshot.val())
              : [];
            return snapshotVal;
          });

        districtAnalytics.push(schoolAnalytics);
      })
    );

    let districtAnalyticsFlat = districtAnalytics.flat();

    const sessionsData = districtAnalyticsFlat.filter(
      (analytic: any) => analytic.session
    );
    const sessionsValue = sessionsData.reduce(
      (total: any, next: any) => total + (next.session || 0),
      0
    );

    const pointsData = districtAnalyticsFlat.filter(
      (analytic: any) => analytic.points
    );
    const pointsValue = pointsData.reduce(
      (total: any, next: any) => total + (next.points || 0),
      0
    );

    const activitiesCompletedData = districtAnalyticsFlat.filter(
      (analytic: any) =>
        analytic.activity0 ||
        analytic.activity1 ||
        analytic.activity2 ||
        analytic.activity3 ||
        analytic.activity4 ||
        analytic.activity5 ||
        analytic.activity6 ||
        analytic.activity7
    );
    const activitiesCompletedValue = activitiesCompletedData.reduce(
      (total: any, next: any) =>
        total +
        (next.activity0 || 0) +
        (next.activity1 || 0) +
        (next.activity2 || 0) +
        (next.activity3 || 0) +
        (next.activity4 || 0) +
        (next.activity5 || 0) +
        (next.activity6 || 0) +
        (next.activity7 || 0),
      0
    );

    const newGuardiansEnrolledData = districtAnalyticsFlat.filter(
      (analytic: any) => analytic.guardianEnrolled
    );
    const newGuardiansEnrolledValue = newGuardiansEnrolledData.reduce(
      (total: any, next: any) => total + (next.guardianEnrolled || 0),
      0
    );

    const dateType = "day";

    return {
      sessions: {
        value: sessionsValue,
        data: handleSessionsData(sessionsData, "dateLabel", dateType),
        percentDiff: null,
      },
      points: {
        value: pointsValue,
        data: handlePointsData(pointsData, "dateLabel", dateType),
        percentDiff: null,
      },
      activitiesCompleted: {
        value: activitiesCompletedValue,
        data: handleActivitiesCompletedData(
          activitiesCompletedData,
          "dateLabel",
          dateType
        ),
        percentDiff: null,
      },
      newGuardiansEnrolled: {
        value: newGuardiansEnrolledValue,
        data: handleNewGuardiansEnrolledData(
          newGuardiansEnrolledData,
          "dateLabel",
          dateType
        ),
        percentDiff: null,
      },
    };
  } catch (err) {
    return err;
  }
};

export const fetchSchoolDashboard = async (
  sceneId: string,
  startDate: any,
  endDate: any
) => {
  try {
    if (!Auth.currentUser) {
      return null;
    }

    /* Handle time frame */
    let startAtDate: number;
    let endAtDate: number | null = null;
    startAtDate = startDate.valueOf();
    endAtDate = endDate.valueOf();

    const schoolAnalyticsEventsRef = Database.ref(
      `analytics/schoolAnalytics/${sceneId}/events`
    );
    const schoolAnalytics = await schoolAnalyticsEventsRef
      .orderByChild("time")
      .startAt(startAtDate)
      .endAt(endAtDate)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val() ? Object.values(snapshot.val()) : [];
        return snapshotVal;
      });

    const sessionsData = schoolAnalytics.filter(
      (analytic: any) => analytic.session
    );
    const sessionsValue = sessionsData.reduce(
      (total: any, next: any) => total + (next.session || 0),
      0
    );

    const pointsData = schoolAnalytics.filter(
      (analytic: any) => analytic.points
    );
    const pointsValue = pointsData.reduce(
      (total: any, next: any) => total + (next.points || 0),
      0
    );

    const activitiesCompletedData = schoolAnalytics.filter(
      (analytic: any) =>
        analytic.activity0 ||
        analytic.activity1 ||
        analytic.activity2 ||
        analytic.activity3 ||
        analytic.activity4 ||
        analytic.activity5 ||
        analytic.activity6 ||
        analytic.activity7
    );
    const activitiesCompletedValue = activitiesCompletedData.reduce(
      (total: any, next: any) =>
        total +
        (next.activity0 || 0) +
        (next.activity1 || 0) +
        (next.activity2 || 0) +
        (next.activity3 || 0) +
        (next.activity4 || 0) +
        (next.activity5 || 0) +
        (next.activity6 || 0) +
        (next.activity7 || 0),
      0
    );

    const newGuardiansEnrolledData = schoolAnalytics.filter(
      (analytic: any) => analytic.guardianEnrolled
    );
    const newGuardiansEnrolledValue = newGuardiansEnrolledData.reduce(
      (total: any, next: any) => total + (next.guardianEnrolled || 0),
      0
    );

    const dateType = "day";

    return {
      sessions: {
        value: sessionsValue,
        data: handleSessionsData(sessionsData, "dateLabel", dateType),
        percentDiff: null,
      },
      points: {
        value: pointsValue,
        data: handlePointsData(pointsData, "dateLabel", dateType),
        percentDiff: null,
      },
      activitiesCompleted: {
        value: activitiesCompletedValue,
        data: handleActivitiesCompletedData(
          activitiesCompletedData,
          "dateLabel",
          dateType
        ),
        percentDiff: null,
      },
      newGuardiansEnrolled: {
        value: newGuardiansEnrolledValue,
        data: handleNewGuardiansEnrolledData(
          newGuardiansEnrolledData,
          "dateLabel",
          dateType
        ),
        percentDiff: null,
      },
    };
  } catch (err) {
    return err;
  }
};

function totalGroup(data: any) {
  return Object.keys(data)
    .map((key) => ({
      label: key === "" ? "N/A" : key,
      amount: data[key].length,
    }))
    .sort((a, b) => b.amount - a.amount);
}
export const adminGuardianAnalytics = async (): Promise<any> => {
  try {
    const guardiansSnapshot = await new Promise<any>((resolve) =>
      guardiansRef
        .orderByChild("onboardingComplete")
        .equalTo(true)
        .once("value", resolve)
    );
    let guardians = guardiansSnapshot.val()
      ? Object.values(guardiansSnapshot.val()).map((g: any) => ({
          ...g,
          language:
            (language_array.find((i: any) => i.language === g.language) as any)
              .name || "Unknown",
        }))
      : [];

    const ethnicityBreakdown = totalGroup(_.groupBy(guardians, "ethnicity"));
    const relationshipBreakdown = totalGroup(
      _.groupBy(guardians, "relationship")
    );

    const gradeLevels = guardians
      .map((guardian: any) =>
        guardian.gradeLevels
          ? guardian.gradeLevels.map((z: any) => z.grades).flat()
          : []
      )
      .flat()
      .map((item) => ({ gradeLevels: item }));

    const priority = (el: string) => {
      const priorityList = [
        "Preschool",
        "Kindergarten",
        "1st grade",
        "2nd grade",
        "3rd grade",
        "4th grade",
        "5th grade",
        "6th grade",
        "7th grade",
        "8th grade",
        "9th grade",
        "10th grade",
        "11th grade",
        "12th grade",
      ];
      return priorityList.indexOf(el);
    };
    const gradeLevelBreakdown = totalGroup(
      _.groupBy(gradeLevels, "gradeLevels")
    ).sort((a: any, b: any) => priority(a.label) - priority(b.label));

    const languageBreakdown = totalGroup(_.groupBy(guardians, "language"));

    return {
      guardians,
      ethnicity: ethnicityBreakdown,
      relationship: relationshipBreakdown,
      gradeLevel: gradeLevelBreakdown,
      language: languageBreakdown,
    };
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const schoolGuardianAnalytics = async (
  sceneId: string
): Promise<any> => {
  try {
    const guardiansSnapshot = await new Promise<any>((resolve) =>
      guardiansRef
        .orderByChild(`schoolIds/${sceneId}`)
        .equalTo(true)
        .once("value", resolve)
    );
    let guardians = guardiansSnapshot.val()
      ? Object.values(guardiansSnapshot.val())
          .map((g: any) => {
            const lang = language_array.find(
              (i: any) => i.language === g.language
            ) as any;

            return {
              ...g,
              language: lang ? (lang.name ? lang.name : "Unknown") : "Unknown",
            };
          })
          .filter((guardian: any) => guardian.onboardingComplete)
      : [];

    const ethnicityBreakdown = totalGroup(_.groupBy(guardians, "ethnicity"));
    const relationshipBreakdown = totalGroup(
      _.groupBy(guardians, "relationship")
    );

    const gradeLevels = guardians
      .map((guardian: any) =>
        guardian.gradeLevels
          ? guardian.gradeLevels
              .filter((gradelevel: any) => gradelevel.schoolId === sceneId)
              .map((z: any) => z.grades)
              .flat()
          : []
      )
      .flat()
      .map((item) => ({ gradeLevels: item }));

    const priority = (el: string) => {
      const priorityList = [
        "Preschool",
        "Kindergarten",
        "1st grade",
        "2nd grade",
        "3rd grade",
        "4th grade",
        "5th grade",
        "6th grade",
        "7th grade",
        "8th grade",
        "9th grade",
        "10th grade",
        "11th grade",
        "12th grade",
      ];
      return priorityList.indexOf(el);
    };
    const gradeLevelBreakdown = totalGroup(
      _.groupBy(gradeLevels, "gradeLevels")
    ).sort((a: any, b: any) => priority(a.label) - priority(b.label));

    const languageBreakdown = totalGroup(_.groupBy(guardians, "language"));

    return {
      guardians,
      ethnicity: ethnicityBreakdown,
      relationship: relationshipBreakdown,
      gradeLevel: gradeLevelBreakdown,
      language: languageBreakdown,
    };
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const districtGuardianAnalytics = async (schoolIds): Promise<any> => {
  try {
    let guardians: any = [];

    await Promise.all(
      schoolIds.map(async (schoolId) => {
        const guardiansSnapshot = await new Promise<any>((resolve) =>
          guardiansRef
            .orderByChild(`schoolIds/${schoolId}`)
            .equalTo(true)
            .once("value", resolve)
        );
        let schoolGuardians = guardiansSnapshot.val()
          ? Object.values(guardiansSnapshot.val())
              .map((g: any) => ({
                ...g,
                language:
                  (language_array.find(
                    (i: any) => i.language === g.language
                  ) as any).name || "Unknown",
              }))
              .filter((guardian: any) => guardian.onboardingComplete)
          : [];

        guardians.push(schoolGuardians);
      })
    );

    let guardiansFlat = guardians.flat();

    const ethnicityBreakdown = totalGroup(
      _.groupBy(guardiansFlat, "ethnicity")
    );
    const relationshipBreakdown = totalGroup(
      _.groupBy(guardiansFlat, "relationship")
    );

    const gradeLevels = guardiansFlat
      .map((guardian: any) =>
        guardian.gradeLevels
          ? guardian.gradeLevels.map((z: any) => z.grades).flat()
          : []
      )
      .flat()
      .map((item) => ({ gradeLevels: item }));

    const priority = (el: string) => {
      const priorityList = [
        "Preschool",
        "Kindergarten",
        "1st grade",
        "2nd grade",
        "3rd grade",
        "4th grade",
        "5th grade",
        "6th grade",
        "7th grade",
        "8th grade",
        "9th grade",
        "10th grade",
        "11th grade",
        "12th grade",
      ];
      return priorityList.indexOf(el);
    };
    const gradeLevelBreakdown = totalGroup(
      _.groupBy(gradeLevels, "gradeLevels")
    ).sort((a: any, b: any) => priority(a.label) - priority(b.label));

    const languageBreakdown = totalGroup(_.groupBy(guardiansFlat, "language"));

    return {
      guardians: guardiansFlat,
      ethnicity: ethnicityBreakdown,
      relationship: relationshipBreakdown,
      gradeLevel: gradeLevelBreakdown,
      language: languageBreakdown,
    };
  } catch (err) {
    console.error(err);
    return null;
  }
};

function handleActivityTypeData(
  data: any[],
  dateKey: string,
  dateType: string,
  key: string
) {
  let dates: any[] = [];
  data.map((dataItem) => {
    const time = moment(dataItem[dateKey]).startOf(dateType).format("L");
    const dateIndex = dates.findIndex((date) => date.x === time);
    if (dateIndex > -1) {
      dates[dateIndex] = {
        ...dates[dateIndex],
        y: dates[dateIndex].y + (dataItem[key] || 0),
      };
    } else {
      dates.push({
        x: time,
        y: dataItem[key] || 0,
      });
    }
    return dataItem;
  });
  return dates.sort((a, b) => (new Date(a.x) as any) - (new Date(b.x) as any));
}

export const readAllAdminActivities = async (): Promise<any> => {
  try {
    let activities = await Database.ref(`activities`)
      .orderByChild("allSchools")
      .equalTo(true)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal ? Object.values(snapshotVal) : [];
      });
    return activities;
  } catch (err) {
    console.error(err);
    return [];
  }
};
export const readAllSchools = async (): Promise<any> => {
  try {
    const schoolsRef = Database.ref("schools");
    const schoolsSnapshot = await new Promise<any>((resolve) =>
      schoolsRef.once("value", resolve)
    );
    let schools = schoolsSnapshot.val();
    return Object.values(schools);
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const fetchAdminActivityAnalytics = async (
  startDate: any,
  endDate: any
) => {
  try {
    if (!Auth.currentUser) {
      return null;
    }

    /* Handle time frame */
    let startAtDate: number;
    let endAtDate: number | null = null;
    startAtDate = startDate.valueOf();
    endAtDate = endDate.valueOf();

    const totalAnalyticsEventsRef = Database.ref(
      "analytics/allAnalytics/events"
    );
    const adminAnalytics = await totalAnalyticsEventsRef
      .orderByChild("time")
      .startAt(startAtDate)
      .endAt(endAtDate)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val() ? Object.values(snapshot.val()) : [];
        return snapshotVal;
      });

    /* Charts */
    const activity0Data = adminAnalytics.filter(
      (analytic: any) => analytic.activity0
    );
    const activity1Data = adminAnalytics.filter(
      (analytic: any) => analytic.activity1
    );
    const activity2Data = adminAnalytics.filter(
      (analytic: any) => analytic.activity2
    );
    const activity3Data = adminAnalytics.filter(
      (analytic: any) => analytic.activity3
    );
    const activity4Data = adminAnalytics.filter(
      (analytic: any) => analytic.activity4
    );
    const activity5Data = adminAnalytics.filter(
      (analytic: any) => analytic.activity5
    );
    const activity6Data = adminAnalytics.filter(
      (analytic: any) => analytic.activity6
    );
    const activity7Data = adminAnalytics.filter(
      (analytic: any) => analytic.activity7
    );
    const pointsData = adminAnalytics.filter(
      (analytic: any) => analytic.points
    );
    const viewsData = adminAnalytics.filter((analytic: any) => {
      return Object.keys(analytic).some((txt: string) =>
        txt.includes("activityView")
      );
    });

    /* Activites */
    const activities = await readAllAdminActivities();
    const activityData = activities.map((act: any) => {
      const completedDataForActivity = adminAnalytics.filter(
        (analytic: any) => analytic[`activity${act.id}`]
      );
      const timesCompleted = completedDataForActivity.reduce(
        (total: any, next: any) => total + (next[`activity${act.id}`] || 0),
        0
      );
      const viewDataForActivity = adminAnalytics.filter(
        (analytic: any) => analytic[`activityView${act.id}`]
      );
      const timesViewed = viewDataForActivity.reduce(
        (total: any, next: any) => total + (next[`activityView${act.id}`] || 0),
        0
      );

      return {
        id: act.id,
        name: act.name,
        theme: act.theme,
        views: timesViewed,
        totalPoints: (timesCompleted as number) * act.points,
        timesCompleted: timesCompleted,
      };
    });

    /* Schools */
    const schools = await readAllSchools();
    const schoolData = await Promise.all(
      schools.map(async (school: any) => {
        const schoolAnalyticsTotalRef = Database.ref(
          `analytics/schoolAnalytics/${school.id}/events/total`
        );
        const schoolAnalyticsTotal = await schoolAnalyticsTotalRef
          .once("value")
          .then(function (snapshot) {
            const snapshotVal = snapshot.val();
            return snapshotVal;
          });

        const totalPointsForSchool = schoolAnalyticsTotal
          ? schoolAnalyticsTotal.points || 0
          : 0;
        const pointsPerGuardian = schoolAnalyticsTotal
          ? schoolAnalyticsTotal.points && school.numberOfGuardians
            ? schoolAnalyticsTotal.points / school.numberOfGuardians
            : 0
          : 0;
        const activitiesPerGuardian =
          schoolAnalyticsTotal && school.numberOfGuardians
            ? ((schoolAnalyticsTotal.activity0 || 0) +
                (schoolAnalyticsTotal.activity1 || 0) +
                (schoolAnalyticsTotal.activity2 || 0) +
                (schoolAnalyticsTotal.activity3 || 0) +
                (schoolAnalyticsTotal.activity4 || 0) +
                (schoolAnalyticsTotal.activity5 || 0) +
                (schoolAnalyticsTotal.activity6 || 0) +
                (schoolAnalyticsTotal.activity7 || 0)) /
              school.numberOfGuardians
            : 0;

        return {
          id: school.id,
          name: school.name,
          totalPointsForSchool: totalPointsForSchool || 0,
          pointsPerGuardian: pointsPerGuardian
            ? pointsPerGuardian.toFixed(1)
            : 0,
          activitiesPerGuardian: activitiesPerGuardian
            ? activitiesPerGuardian.toFixed(1)
            : 0,
        };
      })
    );

    const dateType = "day";

    return {
      charts: {
        activityByType: {
          activity0: handleActivityTypeData(
            activity0Data,
            "dateLabel",
            dateType,
            "activity0"
          ),
          activity1: handleActivityTypeData(
            activity1Data,
            "dateLabel",
            dateType,
            "activity1"
          ),
          activity2: handleActivityTypeData(
            activity2Data,
            "dateLabel",
            dateType,
            "activity2"
          ),
          activity3: handleActivityTypeData(
            activity3Data,
            "dateLabel",
            dateType,
            "activity3"
          ),
          activity4: handleActivityTypeData(
            activity4Data,
            "dateLabel",
            dateType,
            "activity4"
          ),
          activity5: handleActivityTypeData(
            activity5Data,
            "dateLabel",
            dateType,
            "activity5"
          ),
          activity6: handleActivityTypeData(
            activity6Data,
            "dateLabel",
            dateType,
            "activity6"
          ),
          activity7: handleActivityTypeData(
            activity7Data,
            "dateLabel",
            dateType,
            "activity7"
          ),
        },
        totalPoints: {
          data: handlePointsData(pointsData, "dateLabel", dateType),
          percentDiff: null,
        },
        activityViews: {
          data: handleViewsData(viewsData, "dateLabel", dateType),
          percentDiff: null,
        },
      },
      activites: activityData,
      schools: schoolData,
    };
  } catch (err) {
    return err;
  }
};

export const fetchSchoolActivityAnalytics = async (
  schoolId: string,
  startDate: any,
  endDate: any
) => {
  try {
    if (!Auth.currentUser) {
      return null;
    }

    /* Handle time frame */
    let startAtDate: number;
    let endAtDate: number | null = null;
    startAtDate = startDate.valueOf();
    endAtDate = endDate.valueOf();

    const schoolAnalyticsEventsRef = Database.ref(
      `analytics/schoolAnalytics/${schoolId}/events`
    );
    const schoolAnalytics = await schoolAnalyticsEventsRef
      .orderByChild("time")
      .startAt(startAtDate)
      .endAt(endAtDate)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val() ? Object.values(snapshot.val()) : [];
        return snapshotVal;
      });

    /* Charts */
    const activity0Data = schoolAnalytics.filter(
      (analytic: any) => analytic.activity0
    );
    const activity1Data = schoolAnalytics.filter(
      (analytic: any) => analytic.activity1
    );
    const activity2Data = schoolAnalytics.filter(
      (analytic: any) => analytic.activity2
    );
    const activity3Data = schoolAnalytics.filter(
      (analytic: any) => analytic.activity3
    );
    const activity4Data = schoolAnalytics.filter(
      (analytic: any) => analytic.activity4
    );
    const activity5Data = schoolAnalytics.filter(
      (analytic: any) => analytic.activity5
    );
    const activity6Data = schoolAnalytics.filter(
      (analytic: any) => analytic.activity6
    );
    const activity7Data = schoolAnalytics.filter(
      (analytic: any) => analytic.activity7
    );
    const pointsData = schoolAnalytics.filter(
      (analytic: any) => analytic.points
    );
    const viewsData = schoolAnalytics.filter((analytic: any) => {
      return Object.keys(analytic).some((txt: string) =>
        txt.includes("activityView")
      );
    });

    /* Activites */
    const { curriculum } = await readSchool(schoolId);
    let items = [];
    if (curriculum) {
      if (curriculum) {
        const curriculumData = await readCurriculum(curriculum);
        if (curriculumData.activities) {
          items = curriculumData.activities;
        }
      }
    }

    const activities = await readSchoolActivities(
      schoolId,
      items ? items : null
    );
    const activityData = await Promise.all(
      activities.map(async (act: any) => {
        const completedDataForActivity = schoolAnalytics.filter(
          (analytic: any) => analytic[`activity${act.id}`]
        );
        const timesCompleted = completedDataForActivity.reduce(
          (total: any, next: any) => total + (next[`activity${act.id}`] || 0),
          0
        );
        const viewDataForActivity = schoolAnalytics.filter(
          (analytic: any) => analytic[`activityView${act.id}`]
        );
        const timesViewed = viewDataForActivity.reduce(
          (total: any, next: any) =>
            total + (next[`activityView${act.id}`] || 0),
          0
        );

        // Check for activity responses
        const responses = await Database.ref(
          `activityResponses/${schoolId}/${act.id}`
        )
          .once("value")
          .then((snapshot) =>
            snapshot.exists() ? Object.values(snapshot.val()) : []
          );
        const sortedResponses = responses.sort(
          (a: any, b: any) => b.time - a.time
        );

        return {
          id: act.id,
          name: act.name,
          theme: act.theme,
          views: timesViewed,
          totalPoints: (timesCompleted as number) * act.points,
          timesCompleted: timesCompleted,
          allSchools: act.allSchools,
          responses: sortedResponses,
        };
      })
    );
    const filteredActivityData = activityData.filter(
      (act: any) => act.views || act.timesCompleted
    );
    const sortedActivityData = filteredActivityData.sort(
      (a: any, b: any) => a.theme - b.theme
    );

    const dateType = "day";

    return {
      charts: {
        activityByType: {
          activity0: handleActivityTypeData(
            activity0Data,
            "dateLabel",
            dateType,
            "activity0"
          ),
          activity1: handleActivityTypeData(
            activity1Data,
            "dateLabel",
            dateType,
            "activity1"
          ),
          activity2: handleActivityTypeData(
            activity2Data,
            "dateLabel",
            dateType,
            "activity2"
          ),
          activity3: handleActivityTypeData(
            activity3Data,
            "dateLabel",
            dateType,
            "activity3"
          ),
          activity4: handleActivityTypeData(
            activity4Data,
            "dateLabel",
            dateType,
            "activity4"
          ),
          activity5: handleActivityTypeData(
            activity5Data,
            "dateLabel",
            dateType,
            "activity5"
          ),
          activity6: handleActivityTypeData(
            activity6Data,
            "dateLabel",
            dateType,
            "activity6"
          ),
          activity7: handleActivityTypeData(
            activity7Data,
            "dateLabel",
            dateType,
            "activity7"
          ),
        },
        activityViews: {
          data: handleViewsData(viewsData, "dateLabel", dateType),
          percentDiff: null,
        },
        totalPoints: {
          data: handlePointsData(pointsData, "dateLabel", dateType),
          percentDiff: null,
        },
      },
      activites: sortedActivityData,
    };
  } catch (err) {
    return err;
  }
};

/* District Activity Analytics */
export const readSchoolForDistrict = async (schoolId) => {
  try {
    const schoolRef = Database.ref(`schools/${schoolId}`);
    const schoolSnapshot = await new Promise<any>((resolve) =>
      schoolRef.once("value", resolve)
    );
    let school = schoolSnapshot.val();
    const allGuardians = await readSchoolGuardians(school.id);
    const arrayOfGuardians = allGuardians
      ? Object.values(allGuardians).filter(
          (guardian) => (guardian as any).onboardingComplete
        )
      : [];
    school.numberOfGuardians = arrayOfGuardians.length;
    return school;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const fetchDistrictActivityAnalytics = async (
  schoolIds: any,
  startDate: any,
  endDate: any
) => {
  try {
    if (!Auth.currentUser) {
      return null;
    }

    /* Handle time frame */
    let startAtDate: number;
    let endAtDate: number | null = null;
    startAtDate = startDate.valueOf();
    endAtDate = endDate.valueOf();

    let districtAnalytics: any = [];

    await Promise.all(
      schoolIds.map(async (schoolId) => {
        const schoolAnalyticsEventsRef = Database.ref(
          `analytics/schoolAnalytics/${schoolId}/events`
        );
        const schoolAnalytics = await schoolAnalyticsEventsRef
          .orderByChild("time")
          .startAt(startAtDate)
          .endAt(endAtDate)
          .once("value")
          .then(function (snapshot) {
            const snapshotVal = snapshot.val()
              ? Object.values(snapshot.val())
              : [];
            return snapshotVal;
          });

        districtAnalytics.push(schoolAnalytics);
      })
    );

    let districtAnalyticsFlat = districtAnalytics.flat();

    /* Charts */
    const activity0Data = districtAnalyticsFlat.filter(
      (analytic: any) => analytic.activity0
    );
    const activity1Data = districtAnalyticsFlat.filter(
      (analytic: any) => analytic.activity1
    );
    const activity2Data = districtAnalyticsFlat.filter(
      (analytic: any) => analytic.activity2
    );
    const activity3Data = districtAnalyticsFlat.filter(
      (analytic: any) => analytic.activity3
    );
    const activity4Data = districtAnalyticsFlat.filter(
      (analytic: any) => analytic.activity4
    );
    const activity5Data = districtAnalyticsFlat.filter(
      (analytic: any) => analytic.activity5
    );
    const activity6Data = districtAnalyticsFlat.filter(
      (analytic: any) => analytic.activity6
    );
    const activity7Data = districtAnalyticsFlat.filter(
      (analytic: any) => analytic.activity7
    );
    const pointsData = districtAnalyticsFlat.filter(
      (analytic: any) => analytic.points
    );
    const viewsData = districtAnalyticsFlat.filter((analytic: any) => {
      return Object.keys(analytic).some((txt: string) =>
        txt.includes("activityView")
      );
    });

    /* Activites */
    let activities: any = [];
    const adminActivites = await readAllAdminActivities();

    activities = [...adminActivites];

    await Promise.all(
      schoolIds.map(
        async (schoolId) =>
          await Database.ref(`activities`)
            .orderByChild("schoolId")
            .equalTo(schoolId)
            .once("value")
            .then(function (snapshot) {
              const snapshotVal = snapshot.val();
              const activitieItems = snapshotVal
                ? Object.values(snapshotVal).filter(
                    (schoolAct: any) => !schoolAct.archived
                  )
                : [];

              activities = [...activities, ...activitieItems];
            })
      )
    );

    const activityData = activities.map((act: any) => {
      const completedDataForActivity = districtAnalyticsFlat.filter(
        (analytic: any) => analytic[`activity${act.id}`]
      );
      const timesCompleted = completedDataForActivity.reduce(
        (total: any, next: any) => total + (next[`activity${act.id}`] || 0),
        0
      );
      const viewDataForActivity = districtAnalyticsFlat.filter(
        (analytic: any) => analytic[`activityView${act.id}`]
      );
      const timesViewed = viewDataForActivity.reduce(
        (total: any, next: any) => total + (next[`activityView${act.id}`] || 0),
        0
      );

      return {
        id: act.id,
        name: act.name,
        theme: act.theme,
        views: timesViewed,
        totalPoints: (timesCompleted as number) * act.points,
        timesCompleted: timesCompleted,
      };
    });

    /* Schools */
    const schools = await Promise.all(
      schoolIds.map(async (schoolId) => await readSchool(schoolId))
    );
    const schoolData = await Promise.all(
      schools.map(async (school: any) => {
        const schoolAnalyticsTotalRef = Database.ref(
          `analytics/schoolAnalytics/${school.id}/events/total`
        );
        const schoolAnalyticsTotal = await schoolAnalyticsTotalRef
          .once("value")
          .then(function (snapshot) {
            const snapshotVal = snapshot.val();
            return snapshotVal;
          });

        const totalPointsForSchool = schoolAnalyticsTotal
          ? schoolAnalyticsTotal.points || 0
          : 0;
        const pointsPerGuardian = schoolAnalyticsTotal
          ? schoolAnalyticsTotal.points
            ? schoolAnalyticsTotal.points / school.numberOfGuardians
            : 0
          : 0;
        const activitiesPerGuardian = schoolAnalyticsTotal
          ? ((schoolAnalyticsTotal.activity0 || 0) +
              (schoolAnalyticsTotal.activity1 || 0) +
              (schoolAnalyticsTotal.activity2 || 0) +
              (schoolAnalyticsTotal.activity3 || 0) +
              (schoolAnalyticsTotal.activity4 || 0) +
              (schoolAnalyticsTotal.activity5 || 0) +
              (schoolAnalyticsTotal.activity6 || 0) +
              (schoolAnalyticsTotal.activity7 || 0)) /
            school.numberOfGuardians
          : 0;

        return {
          id: school.id,
          name: school.name,
          totalPointsForSchool: totalPointsForSchool || 0,
          pointsPerGuardian: pointsPerGuardian
            ? pointsPerGuardian.toFixed(1)
            : 0,
          activitiesPerGuardian: activitiesPerGuardian
            ? activitiesPerGuardian.toFixed(1)
            : 0,
        };
      })
    );

    const dateType = "day";

    return {
      charts: {
        activityByType: {
          activity0: handleActivityTypeData(
            activity0Data,
            "dateLabel",
            dateType,
            "activity0"
          ),
          activity1: handleActivityTypeData(
            activity1Data,
            "dateLabel",
            dateType,
            "activity1"
          ),
          activity2: handleActivityTypeData(
            activity2Data,
            "dateLabel",
            dateType,
            "activity2"
          ),
          activity3: handleActivityTypeData(
            activity3Data,
            "dateLabel",
            dateType,
            "activity3"
          ),
          activity4: handleActivityTypeData(
            activity4Data,
            "dateLabel",
            dateType,
            "activity4"
          ),
          activity5: handleActivityTypeData(
            activity5Data,
            "dateLabel",
            dateType,
            "activity5"
          ),
          activity6: handleActivityTypeData(
            activity6Data,
            "dateLabel",
            dateType,
            "activity6"
          ),
          activity7: handleActivityTypeData(
            activity7Data,
            "dateLabel",
            dateType,
            "activity7"
          ),
        },
        totalPoints: {
          data: handlePointsData(pointsData, "dateLabel", dateType),
          percentDiff: null,
        },
        activityViews: {
          data: handleViewsData(viewsData, "dateLabel", dateType),
          percentDiff: null,
        },
      },
      activites: activityData,
      schools: schoolData,
    };
  } catch (err) {
    return err;
  }
};

/* Guardian Modal */
export const guardianAnalytics = async (guardianId: string) => {
  if (guardianId) {
    const userTotalSessionsRef = Database.ref(
      `analytics/userAnalytics/${guardianId}/events/total/session`
    );
    const userRecentAnalyticsRef = Database.ref(
      `analytics/userAnalytics/${guardianId}/recentAnalytics`
    );

    /* Last Activity */
    const lastActivity = await userRecentAnalyticsRef
      .orderByChild("time")
      .limitToLast(1)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val()
          ? (Object.values(snapshot.val()) as any)[0].event
          : "";
        return snapshotVal;
      });

    const recentUserAnalytics = await userRecentAnalyticsRef
      .orderByChild("time")
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val() ? Object.values(snapshot.val()) : [];
        return snapshotVal;
      });

    /* Fixed recentAnalytics */
    const analyticResults: any[] = [];
    recentUserAnalytics.map((analytic: any) => {
      if (analytic.event && analytic.time) {
        analyticResults.push({
          name: analytic.event,
          time: analytic.time,
          date: moment(analytic.time).format("LTS"),
        });
      }
      return analytic;
    });

    const occurrenceDay = function (occurrence) {
      return moment(occurrence.time).startOf("day").valueOf();
    };
    const groupToDay = function (group, day) {
      return {
        day: moment(Number(day)).format("LL"),
        events: group.sort((a: any, b: any) => b.time - a.time),
        time: day,
      };
    };

    const resultByDay = _.chain(analyticResults)
      .groupBy(occurrenceDay)
      .map(groupToDay)
      .orderBy("time", "desc")
      .value();

    /* Sessions */
    const totalUserSessions = await userTotalSessionsRef
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val() ? snapshot.val() : 0;
        return snapshotVal;
      });

    return {
      lastActivity: lastActivity,
      activityFeed: resultByDay,
      sessions: totalUserSessions,
    };
  } else {
    return {
      lastActivity: "",
      activityFeed: [],
      sessions: 0,
    };
  }
};

export const unsubscribeFromEmail = async (
  userId: string,
  emailType: string,
  schoolId: string
): Promise<void> => {
  const unsubscribeFromEmail = Functions.httpsCallable("unsubscribeFromEmail");
  await unsubscribeFromEmail({ userId, emailType, schoolId });
  return;
};

export const deleteGuardian = async (guardianId: string): Promise<void> => {
  const guardianRef = Database.ref(`guardians/${guardianId}`);
  await guardianRef.remove();

  const deleteGuardianAuth = Functions.httpsCallable("deleteGuardian");
  await deleteGuardianAuth({ guardianId });
  return;
};

export const resetGuardian = async (guardianId: string): Promise<any> => {
  const guardianRef = Database.ref(`guardians/${guardianId}`);
  await guardianRef.update({
    completedActivities: null,
    completedScorecards: null,
    points: 0,
  });

  const updatedGuardianRef = await new Promise<any>((resolve) =>
    guardianRef.once("value", resolve)
  );
  const updatedGuardian = updatedGuardianRef.val() as any;
  return updatedGuardian;
};

const uploadHosting = async (
  route: string,
  file: File | Blob | any
): Promise<any> => {
  let storageRef = Storage.ref(route);
  let data: Blob | ArrayBuffer;
  if (file instanceof Blob || file._data) {
    data = file;
  } else {
    data = await readFile(file);
  }
  await storageRef.put(data, { contentType: file.type });

  const url = await storageRef.getDownloadURL();
  return url;
};

// Admin Hosting Files
export const uploadAdminHostingFile = async (resource: any): Promise<any> => {
  try {
    const currentTime = moment().valueOf();
    const uuid = uuidv4();
    const route = `resources/hosting/file_${uuid}`;
    const url = await uploadHosting(route, resource.file);

    resource.url = url;
    resource.time = currentTime;
    delete resource.file;

    const resourceRef = await Database.ref(`resources`).push(resource);
    (resource as any).id = resourceRef.key;

    await resourceRef.set(resource);

    const resourceSnapshot = await new Promise<any>((resolve) =>
      resourceRef.once("value", resolve)
    );
    const createdResource = resourceSnapshot.val() as any;

    return createdResource;
  } catch (err) {
    console.error(err);
  }
};

export const deleteAdminHostingFile = async (
  resourceId: string
): Promise<any> => {
  try {
    await Database.ref(`resources/${resourceId}`).remove();
  } catch (err) {
    console.error(err);
  }
};

export const readHostingFiles = async (): Promise<any> => {
  try {
    let adminFiles = await Database.ref(`resources`)
      .orderByChild("hosting")
      .equalTo(true)
      .once("value")
      .then(function (snapshot) {
        let adminItems: any[] = [];
        const snapshotVal = snapshot.val();
        const files = snapshot.val() ? Object.values(snapshotVal) : [];

        files.map((file: any) => {
          if (!file.schoolId) {
            adminItems.push(file);
          }
          return file;
        });

        return adminItems;
      });
    return adminFiles;
  } catch (err) {
    console.error(err);
    return [];
  }
};

// Admin Hosting Files
export const uploadSchoolHostingFile = async (resource: any): Promise<any> => {
  try {
    const currentTime = moment().valueOf();
    const uuid = uuidv4();
    const route = `resources/hosting/${resource.schoolId}/file_${uuid}`;
    const url = await uploadHosting(route, resource.file);

    resource.url = url;
    resource.time = currentTime;
    delete resource.file;

    const resourceRef = await Database.ref(`resources`).push(resource);
    (resource as any).id = resourceRef.key;

    await resourceRef.set(resource);

    const resourceSnapshot = await new Promise<any>((resolve) =>
      resourceRef.once("value", resolve)
    );
    const createdResource = resourceSnapshot.val() as any;

    return createdResource;
  } catch (err) {
    console.error(err);
  }
};

export const deleteSchoolHostingFile = async (
  resourceId: string
): Promise<any> => {
  try {
    await Database.ref(`resources/${resourceId}`).remove();
  } catch (err) {
    console.error(err);
  }
};

export const readSchoolHostingFiles = async (
  schoolId: string
): Promise<any> => {
  try {
    let adminFiles = await Database.ref(`resources`)
      .orderByChild("hosting")
      .equalTo(true)
      .once("value")
      .then(function (snapshot) {
        let schoolItems: any[] = [];
        const snapshotVal = snapshot.val();
        const files = snapshot.val() ? Object.values(snapshotVal) : [];

        files.map((file: any) => {
          if (file.schoolId === schoolId) {
            schoolItems.push(file);
          }
          return file;
        });

        return schoolItems;
      });
    return adminFiles;
  } catch (err) {
    console.error(err);
    return [];
  }
};

// School File Upload
const uploadSchoolFile = async (
  route: string,
  file: File | Blob | any
): Promise<any> => {
  let storageRef = Storage.ref(route);
  let data: Blob | ArrayBuffer;
  if (file instanceof Blob || file._data) {
    data = file;
  } else {
    data = await readFile(file);
  }
  const fileUrl = await storageRef
    .put(data, { contentType: file.type })
    .then((snapshot) => {
      return snapshot.ref.getDownloadURL();
    });

  // JPEG or PNG
  if (!["application/pdf", "video/mp4"].includes(file.type)) {
    // Change route to _800x800 image path
    const endingToChange = route.substr(
      route.lastIndexOf("/") + 1,
      route.length - 1
    );
    const newEnding = endingToChange + "_800x800";
    const resizedRoute = route.replace(endingToChange, newEnding);

    // Poll for resized image
    const resizedImageUrl = await getResizedImage(
      10,
      Storage.ref(resizedRoute)
    );
    return resizedImageUrl;
  }
  // Video or PDF
  else {
    return fileUrl;
  }
};

export const uploadSchoolActivityFile = async (
  activityId: number | string,
  file: File | Blob | any
): Promise<any> => {
  const currentTime = uuidv4();
  return uploadSchoolFile(
    `activities/${activityId}/schoolFile_${currentTime}`,
    file
  );
};

export const uploadEvidenceFile = async (
  activityId: number | string,
  file: File | Blob | any
): Promise<any> => {
  const currentTime = uuidv4();
  return uploadSchoolFile(
    `evidence/${activityId}/evidenceFile_${currentTime}`,
    file
  );
};

// Evaluation Responses
export const updateCurrentEvaluationQuestions = async (
  questions: any,
  language: string
) => {
  try {
    const evaluationQuestionsRef = Database.ref(
      `appSettings/parentEvaluationQuestions${language}`
    );
    await evaluationQuestionsRef.set(questions);

    const evaluationQuestionsSnapshot = await new Promise<any>((resolve) =>
      evaluationQuestionsRef.once("value", resolve)
    );
    const currentQuestions = evaluationQuestionsSnapshot.val() as any;
    return currentQuestions;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const readAllEvaluationResponses = async () => {
  try {
    const responsesRef = Database.ref(`evaluationResponses`);
    let responses = await responsesRef.once("value").then(function (snapshot) {
      const snapshotVal = snapshot.val();
      return snapshotVal
        ? Object.values(snapshotVal)
            .map((item: any) => Object.values(item))
            .flat()
        : [];
    });
    return responses.filter((response: any) => !response.archived);
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const readSchoolEvaluationResponses = async (schoolId: string) => {
  try {
    const responsesRef = Database.ref(`evaluationResponses/${schoolId}`);
    let responses = await responsesRef
      .orderByChild("archived")
      .equalTo(null)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal ? Object.values(snapshotVal) : [];
      });
    return responses;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const fetchGuardainsEvaluations = async (schoolId: string) => {
  try {
    let guardians = await Database.ref(`guardians`)
      .orderByChild(`schoolIds/${schoolId}`)
      .equalTo(true)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal;
      });
    let guardiansWithEvaluation = guardians
      ? Object.values(guardians).filter(
          (i: any) => i.completedEvaluations && i.onboardingComplete
        )
      : [];

    let evaluations: any = [];
    guardiansWithEvaluation.map((guardian: any) => {
      Object.values(guardian.completedEvaluations).map((evaluationZ: any) => {
        evaluations.push({
          ...evaluationZ,
          uid: guardian.uid,
          avatar: guardian.avatar,
          username: guardian.username,
        });
        return evaluationZ;
      });
      return guardian;
    });

    let sortedEvaluations = evaluations.sort(
      (a: any, b: any) => b.start - a.start
    );

    return sortedEvaluations;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const fetchGuardainsScorecards = async (schoolId: string) => {
  try {
    let guardians = await Database.ref(`guardians`)
      .orderByChild(`schoolIds/${schoolId}`)
      .equalTo(true)
      .once("value")
      .then(function (snapshot) {
        const snapshotVal = snapshot.val();
        return snapshotVal;
      });
    let guardiansWithScorecard = guardians
      ? Object.values(guardians).filter(
          (i: any) => i.completedScorecards && i.onboardingComplete
        )
      : [];

    let scorecards: any = [];
    guardiansWithScorecard.map((guardian: any) => {
      Object.values(guardian.completedScorecards).map((scorecardX: any) => {
        scorecards.push({
          ...scorecardX,
          uid: guardian.uid,
          avatar: guardian.avatar,
          username: guardian.username,
        });
        return scorecardX;
      });
      return guardian;
    });

    let sortedScorecards = scorecards.sort(
      (a: any, b: any) => b.start - a.start
    );

    return sortedScorecards;
  } catch (err) {
    console.error(err);
    return [];
  }
};

/* Video Shorts */
export const uploadVideoShortThumbnail = async (
  videoShortId: number | string,
  file: File | Blob | any
): Promise<any> => {
  const currentTime = uuidv4();
  return uploadThumbnail(
    `videoShorts/${videoShortId}/thumbnail_${currentTime}`,
    file
  );
};

export const uploadVideoShortVideo = async (
  videoShortId: number | string,
  file: File | Blob | any
): Promise<any> => {
  const currentTime = uuidv4();
  return uploadVideo(`videoShorts/${videoShortId}/video_${currentTime}`, file);
};

export const uploadVideoShortVideoSubmission = async (
  videoShortId: number | string,
  file: File | Blob | any
): Promise<any> => {
  const currentTime = uuidv4();
  return uploadVideo(
    `videoShortsSubmission/${videoShortId}/video_${currentTime}`,
    file
  );
};

export const readAllDistricts = async (): Promise<any> => {
  try {
    const districtsRef = Database.ref("districts");
    const districtsSnapshot = await new Promise<any>((resolve) =>
      districtsRef
        .orderByChild("defaultDistrict")
        .equalTo(false)
        .once("value", resolve)
    );
    let districts =
      (districtsSnapshot.val() as {
        [districtId: string]: any;
      }) || {};
    const districtsWithData = await Promise.all(
      Object.keys(districts).map(
        async (districtId) => await readDistrict(districtId as string)
      )
    );
    return districtsWithData;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const fetchLeaderboardStandings = async (
  sceneId: string,
  startDate: any,
  endDate: any
) => {
  try {
    if (!Auth.currentUser) {
      return null;
    }

    /* Handle time frame */
    let startAtDate: number;
    let endAtDate: number | null = null;
    startAtDate = startDate.valueOf();
    endAtDate = endDate.valueOf();

    const guardians: any[] = await guardianUsersBySchool(sceneId);
    const result = await Promise.all(
      Object.values(guardians)
        .filter((item: any) => item.onboardingComplete)
        .map(async (schoolUser) => {
          const totalPointsThisMonth = await fetchPointsInTimeFrame(
            schoolUser.uid,
            sceneId,
            startAtDate,
            endAtDate
          );
          return {
            ...schoolUser,
            userName: schoolUser.username || "",
            name: schoolUser.displayName
              ? schoolUser.displayName
              : `${schoolUser.firstName} ${schoolUser.lastName}`,
            points: totalPointsThisMonth.totalPoints,
            uid: schoolUser.uid,
            children: schoolUser.children || [],
          };
        })
    );
    return result.sort((a: any, b: any) => b.points - a.points);
  } catch (err) {
    return err;
  }
};

/* Read all non second mile users */
export const guardianUsersBySchool = async (schoolId: string) => {
  const schoolUsers = await guardiansRef
    .orderByChild(`schoolIds/${schoolId}`)
    .equalTo(true)
    .once("value")
    .then(function (snapshot) {
      const snapshotVal = snapshot.val();
      return snapshotVal ? Object.values(snapshotVal) : [];
    });

  return schoolUsers;
};

export const fetchPointsInTimeFrame = async (
  uid: string,
  schoolId: string,
  start,
  end
) => {
  const userAnalyticEvents = await Database.ref("analytics")
    .child(`/userAnalytics/${uid}/events`)
    .orderByChild("time")
    .startAt(start)
    .endAt(end)
    .once("value")
    .then(function (snapshot) {
      const snapshotVal = snapshot.val() ? Object.values(snapshot.val()) : [];
      return snapshotVal;
    });

  const totalPoints = userAnalyticEvents
    .map((item: any) => (item.points || 0) + (item[`points${schoolId}`] || 0))
    .reduce((a, b) => a + b, 0);
  const cleanedEvents = userAnalyticEvents.map((analytic: any) => ({
    dateLabel: analytic.dateLabel,
    points: analytic.points || 0,
  }));
  return {
    totalPoints,
    userAnalyticEvents: cleanedEvents,
  };
};

export const submitVideoShort = async (file: any, userEmail?: string) => {
  let videoShort: any = {};
  const submitVideoShortRef = await Database.ref("submittedVideoShorts").push();
  videoShort.id = submitVideoShortRef.key;

  videoShort.lastUpdated = moment().valueOf();
  videoShort.email = userEmail;

  await submitVideoShortRef.set(videoShort);

  if (file) {
    if (file instanceof File || file instanceof Blob || file._data) {
      let videoShortVideo = file;
      const videoShortVideoUrl = await uploadVideoShortVideoSubmission(
        videoShort.id as string,
        videoShortVideo
      );
      videoShort.video = videoShortVideoUrl;
    }
  }

  await submitVideoShortRef.update(videoShort);
};

export const deleteSchool = async (schoolId: any) => {
  if (!schoolId) {
    return;
  }

  /* Remove from guardian records school */
  try {
    const guardiansWithSchoolId = await Database.ref("guardians")
      .orderByChild(`schoolIds/${schoolId}`)
      .equalTo(true)
      .once("value")
      .then((snapshot) =>
        snapshot.exists() ? Object.values(snapshot.val()) : []
      );
    if (guardiansWithSchoolId.length) {
      // Remove grade levels
      // Remove schoolId
      // Remove school names
      await Promise.all(
        guardiansWithSchoolId.map(async (guardian: any) => {
          const updatedSchoolIds = guardian.schoolIds
            ? { ...guardian.schoolIds, [schoolId]: null }
            : {};
          const updatedGradeLevels = guardian.gradeLevels
            ? guardian.gradeLevels.filter(
                (gradeLevel: any) => gradeLevel.schoolId !== schoolId
              )
            : [];
          /* Reset to onboarding when no schools attached to user */
          const onboardingComplete = Object.values(updatedSchoolIds).find(
            (item: any) => item !== null
          );

          const guardianRef = Database.ref(`guardians/${guardian.uid}`);
          await guardianRef.update({
            onboardingComplete: onboardingComplete ? true : false,
            schoolIds: updatedSchoolIds,
            schoolNames: "",
            gradeLevels: updatedGradeLevels,
          });
        })
      );
    }

    /* Remove activites with school */
    const activitiesWithSchoolId = await Database.ref("activities")
      .orderByChild("schoolId")
      .equalTo(`${schoolId}`)
      .once("value")
      .then((snapshot) =>
        snapshot.exists() ? Object.values(snapshot.val()) : []
      );
    if (activitiesWithSchoolId.length) {
      await Promise.all(
        activitiesWithSchoolId.map(async (activity: any) => {
          const activityRef = Database.ref(`activities/${activity.id}`);
          await activityRef.remove();
        })
      );
    }

    /* Remove school from schoolUsers */
    const schoolUsersWithSchoolId = await Database.ref("schoolUsers")
      .orderByChild(`schools/${schoolId}`)
      .equalTo(true)
      .once("value")
      .then((snapshot) =>
        snapshot.exists() ? Object.values(snapshot.val()) : []
      );
    if (schoolUsersWithSchoolId.length) {
      await Promise.all(
        schoolUsersWithSchoolId.map(async (schoolUser: any) => {
          const schoolUserRef = Database.ref(`schoolUsers/${schoolUser.id}`);
          const updatedSchools = schoolUser.schools
            ? { ...schoolUser.schools, [schoolId]: null }
            : {};
          await schoolUserRef.update({
            schools: updatedSchools,
          });

          // Update user districts
          if (schoolUser.districts) {
            const districtIds = Object.keys(schoolUser.districts);
            if (districtIds.length) {
              districtIds.map(async (districtId: any) => {
                const districtRef = Database.ref(`districts/${districtId}`);
                const district = await districtRef
                  .once("value")
                  .then((snapshot) =>
                    snapshot.exists() ? snapshot.val() : null
                  );
                if (district) {
                  const updatedDistrictSchoolIds = district.schoolIds
                    ? { ...district.schoolIds, [schoolId]: null }
                    : {};

                  /* Check if delete district */
                  const districtHasMoreSchools = Object.values(
                    updatedDistrictSchoolIds
                  ).find((item: any) => item !== null);

                  if (districtHasMoreSchools) {
                    await districtRef.update({
                      schoolIds: updatedDistrictSchoolIds,
                    });
                  } else {
                    await districtRef.remove();
                    const updatedSchoolUserDistricts = schoolUser.districts
                      ? { ...schoolUser.districts, [districtId]: null }
                      : {};
                    await schoolUserRef.update({
                      districts: updatedSchoolUserDistricts,
                    });
                  }
                }
              });
            }
          }
        })
      );
    }

    /* Remove school from districts */
    const districtsWithSchoolId = await Database.ref("districts")
      .orderByChild(`schoolIds/${schoolId}`)
      .equalTo(true)
      .once("value")
      .then((snapshot) =>
        snapshot.exists() ? Object.values(snapshot.val()) : []
      );
    if (districtsWithSchoolId.length) {
      await Promise.all(
        districtsWithSchoolId.map(async (district: any) => {
          const updatedDistrictSchoolIds2 = district.schoolIds
            ? { ...district.schoolIds, [schoolId]: null }
            : {};
          await Database.ref(`districts/${district.id}`).update({
            schoolIds: updatedDistrictSchoolIds2,
          });
        })
      );
    }

    /* Remove school */
    await Database.ref(`schools/${schoolId}`).remove();
  } catch (err) {
    Bugsnag.notify(err as any);
  }
};

export const deleteDistrict = async (districtId: any) => {
  if (!districtId) {
    return;
  }

  try {
    const schoolUsersWithDistrictId = await Database.ref("schoolUsers")
      .orderByChild(`districts/${districtId}`)
      .equalTo(true)
      .once("value")
      .then((snapshot) =>
        snapshot.exists() ? Object.values(snapshot.val()) : []
      );
    if (schoolUsersWithDistrictId.length) {
      await Promise.all(
        schoolUsersWithDistrictId.map(async (schoolUser: any) => {
          const updatedSchoolUserDistricts = schoolUser.districts
            ? { ...schoolUser.districts, [districtId]: null }
            : {};
          await Database.ref(`schoolUsers/${schoolUser.id}`).update({
            districts: updatedSchoolUserDistricts,
          });
        })
      );
    }

    /* Remove district */
    await Database.ref(`districts/${districtId}`).remove();
  } catch (err) {
    Bugsnag.notify(err as any);
  }
};

export const getReportData = async (sceneId: any) => {
  const dateRange = await fetchDateRange();

  // Number of parents enrolled this year
  const guardiansAtSchool = await Database.ref("guardians")
    .orderByChild(`schoolIds/${sceneId}`)
    .equalTo(true)
    .once("value")
    .then((snapshot) =>
      snapshot.exists() ? Object.values(snapshot.val()) : []
    );

  const filteredGuardians = guardiansAtSchool.filter(
    (guardian: any) => guardian.onboardingComplete
  );

  const numberOfParentsThisYear = filteredGuardians.length;

  // Percent of parents enrolled - compare with plan
  const schoolData = await readSchool(sceneId);
  // const percentEnrolledThisYear = numberOfParentsThisYear
  //   ? schoolData.planStudents
  //     ? Math.round((numberOfParentsThisYear / schoolData.planStudents) * 100)
  //     : "0"
  //   : "0";

  // Number of sessions
  const startDate = dateRange
    ? dateRange[0]
      ? moment(dateRange[0]).startOf("month").valueOf()
      : null
    : null;
  const endDate = dateRange
    ? dateRange.length
      ? moment(dateRange[dateRange.length - 1])
          .endOf("month")
          .valueOf()
      : null
    : null;
  const schoolAnalyticData = await Database.ref(
    `analytics/schoolAnalytics/${sceneId}/events`
  )
    .orderByChild("time")
    .startAt(startDate)
    .endAt(endDate)
    .once("value")
    .then((snapshot) =>
      snapshot.exists() ? Object.values(snapshot.val()) : []
    );
  const numberOfSessionsThisYear = schoolAnalyticData.reduce(
    (store: any, analytic: any) => (analytic.session || 0) + store,
    0
  );

  // In-school events
  const inSchoolActivities: any = await readInSchoolActivities(schoolData.id);
  const numberOfInSchoolActivities = inSchoolActivities.length;

  const averageAttendanceInSchool = numberOfInSchoolActivities
    ? inSchoolActivities.reduce(
        (store: any, event: any) => (Number(event.attendees) || 0) + store,
        0
      ) / numberOfInSchoolActivities
    : 0;

  // Guardian analytic mapping
  const guardianAnalyticEvents: any = await Promise.all(
    filteredGuardians.map(async (guardian: any) => {
      const eventsForGuardian = await Database.ref(
        `analytics/userAnalytics/${guardian.uid}/events`
      )
        .orderByChild("time")
        .startAt(startDate)
        .endAt(endDate)
        .once("value")
        .then((snapshot) =>
          snapshot.exists() ? Object.values(snapshot.val()) : []
        );
      /* Add in ethnic data */
      return eventsForGuardian.map((z: any) => ({
        ...z,
        uid: guardian.uid,
        ethnicity: guardian.ethnicity,
      }));
    })
  );

  const flattenGuardianEvents = guardianAnalyticEvents.flat();

  // Monthly Engagement Breakdown
  const schoolThemes = themes(schoolData.schedule);
  const monthlyEngagement = schoolThemes.map((theme) => {
    const eventsFromThisMonth = flattenGuardianEvents.filter(
      (event: any) => moment(event.time).month() === theme.monthOfYear
    );
    const numberOfSessions = eventsFromThisMonth.reduce(
      (store: any, analytic: any) => (analytic.session || 0) + store,
      0
    );

    const uniqueParents = eventsFromThisMonth.filter(
      (v: any, i: any, a: any) =>
        a.findIndex((v2: any) => v2.uid === v.uid) === i
    );

    const numberOfParents = uniqueParents.length;
    const ethnicBreakdown = totalGroup(_.groupBy(uniqueParents, "ethnicity"));

    const inAppEngagement = {
      theme: theme.name,
      numberOfParents,
      numberOfSessions,
      ethnicBreakdown,
    };
    const inSchoolEngagement = inSchoolActivities
      ? inSchoolActivities.filter(
          (i: any) => moment(i.date).month() === theme.monthOfYear
        )
      : [];
    return {
      monthName: monthNames[theme.monthOfYear],
      inAppEngagement,
      inSchoolEngagement,
    };
  });

  // Activity Views Chart
  const viewsData = schoolAnalyticData.filter((analytic: any) => {
    return Object.keys(analytic).some((txt: string) =>
      txt.includes("activityView")
    );
  });

  // Activity Breakdown Chart
  const activity0Data = schoolAnalyticData.filter(
    (analytic: any) => analytic.activity0
  );
  const activity1Data = schoolAnalyticData.filter(
    (analytic: any) => analytic.activity1
  );
  const activity2Data = schoolAnalyticData.filter(
    (analytic: any) => analytic.activity2
  );
  const activity3Data = schoolAnalyticData.filter(
    (analytic: any) => analytic.activity3
  );
  const activity4Data = schoolAnalyticData.filter(
    (analytic: any) => analytic.activity4
  );
  const activity5Data = schoolAnalyticData.filter(
    (analytic: any) => analytic.activity5
  );
  const activity6Data = schoolAnalyticData.filter(
    (analytic: any) => analytic.activity6
  );
  const activity7Data = schoolAnalyticData.filter(
    (analytic: any) => analytic.activity7
  );

  // Ethnicity Chart
  const ethnicityData = await schoolGuardianAnalytics(sceneId);

  // Grade Level Breakdown
  const gradeLevels = filteredGuardians
    .map((guardian: any) =>
      guardian.gradeLevels
        ? guardian.gradeLevels
            .filter((gradelevel: any) => gradelevel.schoolId === sceneId)
            .map((z: any) => z.grades)
            .flat()
        : []
    )
    .flat()
    .map((item) => ({ gradeLevels: item }));

  const priority = (el: string) => {
    const priorityList = [
      "Preschool",
      "Kindergarten",
      "1st grade",
      "2nd grade",
      "3rd grade",
      "4th grade",
      "5th grade",
      "6th grade",
      "7th grade",
      "8th grade",
      "9th grade",
      "10th grade",
      "11th grade",
      "12th grade",
    ];
    return priorityList.indexOf(el);
  };
  const gradeLevelBreakdown = totalGroup(
    _.groupBy(gradeLevels, "gradeLevels")
  ).sort((a: any, b: any) => priority(a.label) - priority(b.label));

  // Language Breakdown
  const guardainsLanguageMap = filteredGuardians.map((z: any) => {
    const lang = language_array.find(
      (i: any) => i.language === z.language
    ) as any;

    return {
      ...z,
      language: lang ? (lang.name ? lang.name : "Unknown") : "Unknown",
    };
  });
  const languageBreakdown = totalGroup(
    _.groupBy(guardainsLanguageMap, "language")
  );

  // Average Eval
  const responses = await readSchoolEvaluationResponses(sceneId);

  const responseQuestions = responses
    ? responses
        .map((response: any) =>
          response.responses
            ? response.responses.map((res1: any) => res1.question)
            : []
        )
        .flat()
    : [];
  const questions = responseQuestions.filter((c, index) => {
    return responseQuestions.indexOf(c) === index;
  });
  const arrayOfResponses = responses
    ? responses.map((res: any) => res.responses).flat()
    : [];

  const arrayOfAnswers = questions
    ? questions
        .map((question) => {
          const questionResponses = arrayOfResponses.filter((res) =>
            res ? res.question === question : false
          );

          const oneRating = questionResponses.filter((a) => a.answer === 1)
            .length;
          const twoRating = questionResponses.filter((a) => a.answer === 2)
            .length;
          const threeRating = questionResponses.filter((a) => a.answer === 3)
            .length;
          const fourRating = questionResponses.filter((a) => a.answer === 4)
            .length;
          const fiveRating = questionResponses.filter((a) => a.answer === 5)
            .length;

          const totalRating = questionResponses.reduce((a, b) => {
            return a + (b.answer || 0);
          }, 0);

          const average = (totalRating / questionResponses.length).toFixed(1);
          const percent = average ? ((average as any) / 5) * 100 : 0;

          return {
            question,
            totalResponses: questionResponses.length,
            average,
            percent,
            oneRating,
            twoRating,
            threeRating,
            fourRating,
            fiveRating,
          };
        })
        .sort((a, b) => b.totalResponses - a.totalResponses)
    : [];
  const averageEvaluations = arrayOfAnswers;

  /* Filter monthly engagement to only show items with values */
  const filteredMonthlyEngagement = monthlyEngagement.filter(
    (engagementMonth: any) =>
      engagementMonth.inSchoolEngagement.length ||
      engagementMonth.inAppEngagement.numberOfParents ||
      engagementMonth.inAppEngagement.numberOfSessions
  );

  return {
    schoolName: schoolData.name,
    schoolYear: `${moment(dateRange[0]).format("YYYY")} - ${moment(
      dateRange[dateRange.length - 1]
    ).format("YYYY")}`,
    schoolLogo: schoolData.properties
      ? schoolData.properties.schoolLogo
        ? schoolData.properties.schoolLogo
        : ""
      : "",
    numberOfParentsThisYear,
    numberOfSessionsThisYear,
    numberOfInSchoolActivities,
    averageAttendanceInSchool: averageAttendanceInSchool
      ? averageAttendanceInSchool.toFixed(2)
      : 0,
    monthlyEngagement: filteredMonthlyEngagement,
    averageEvaluations: averageEvaluations ? averageEvaluations : [],
    /* Charts */
    ethnicityChart: ethnicityData ? ethnicityData.ethnicity : [],
    gradeLevelChart: gradeLevelBreakdown,
    languageChart: languageBreakdown,
    activityViewsChart: handleViewsData(viewsData, "dateLabel", "week"),
    activityBreakdownChart: {
      activity0: handleActivityTypeData(
        activity0Data,
        "dateLabel",
        "week",
        "activity0"
      ),
      activity1: handleActivityTypeData(
        activity1Data,
        "dateLabel",
        "week",
        "activity1"
      ),
      activity2: handleActivityTypeData(
        activity2Data,
        "dateLabel",
        "week",
        "activity2"
      ),
      activity3: handleActivityTypeData(
        activity3Data,
        "dateLabel",
        "week",
        "activity3"
      ),
      activity4: handleActivityTypeData(
        activity4Data,
        "dateLabel",
        "week",
        "activity4"
      ),
      activity5: handleActivityTypeData(
        activity5Data,
        "dateLabel",
        "week",
        "activity5"
      ),
      activity6: handleActivityTypeData(
        activity6Data,
        "dateLabel",
        "week",
        "activity6"
      ),
      activity7: handleActivityTypeData(
        activity7Data,
        "dateLabel",
        "week",
        "activity7"
      ),
    },
  };
};

const uploadGeneratedReport = async (
  route: string,
  file: File | Blob | any
): Promise<any> => {
  let storageRef = Storage.ref(route);
  let data: Blob | ArrayBuffer;
  if (file instanceof Blob || file._data) {
    data = file;
  } else {
    data = await readFile(file);
  }
  await storageRef.put(data, { contentType: file.type });

  const url = await storageRef.getDownloadURL();
  return url;
};

export const addGeneratedReport = async (report: any) => {
  if (report) {
    if (report.schoolId) {
      /* Upload Report PDF */
      const currentTime = moment().valueOf();
      const uuid = uuidv4();
      const route = `/generatedReports/file_${uuid}`;
      const url = await uploadGeneratedReport(route, report.file);

      report.url = url;
      report.date = currentTime;
      delete report.file;

      const genReportRef = await Database.ref(
        `generatedReports/${report.schoolId}`
      ).push(report);
      (report as any).id = genReportRef.key;

      await genReportRef.set(report);
    }
  }
};

export const getGeneratedReports = async (schoolId: any) => {
  const reports = await Database.ref(`generatedReports/${schoolId}`)
    .once("value")
    .then((snapshot) =>
      snapshot.exists() ? Object.values(snapshot.val()) : []
    );

  return reports;
};

export const deleteGeneratedReport = async (schoolId: any, reportId: any) => {
  await Database.ref(`generatedReports/${schoolId}/${reportId}`).remove();
};

export const getAllGeneratedReports = async () => {
  const reports = await Database.ref(`generatedReports`)
    .once("value")
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : {}));
  const flattenedReports = Object.values(reports).reduce(
    (store: any, report: any) => store.concat(Object.values(report)),
    []
  );

  return flattenedReports;
};

export const getGuardiansForPush = async (schoolId: any) => {
  let guardians = await Database.ref(`guardians`)
    .orderByChild(`schoolIds/${schoolId}`)
    .equalTo(true)
    .once("value")
    .then(function (snapshot) {
      const snapshotVal = snapshot.val();
      return snapshotVal;
    });

  let guardiansFiltered = guardians
    ? Object.values(guardians).filter(
        (guardian: any) =>
          guardian.onboardingComplete &&
          guardian.fcmToken &&
          guardian.weeklyScorecardNotifications
      )
    : [];

  return guardiansFiltered;
};

export const sendSchoolPushNotification = async (
  notification: any,
  guardianItems: any
) => {
  const sendSchoolPushNotificationCall = Functions.httpsCallable(
    "schoolPushNotification"
  );
  await sendSchoolPushNotificationCall({
    notification,
    guardianItems,
  });
};

export const addOwnerSchool = async (schoolId: string, email: string) => {
  try {
    /* Check email */
    const userByEmail = await Auth.fetchSignInMethodsForEmail(email);
    if (!userByEmail.length) {
      return "No User";
    }
    const schoolUser: any = await Database.ref(`schoolUsers`)
      .orderByChild("email/address")
      .equalTo(email)
      .once("value")
      .then((snapshot) => Object.values(snapshot.val())[0]);

    const schoolUserId = schoolUser.id;

    if (schoolUser.schools) {
      if (schoolUser.schools) {
        if (schoolUser.schools[schoolId]) {
          return;
        }
      }
    }

    const schoolUserRef = Database.ref(`schoolUsers/${schoolUserId}`);
    const schoolRef = Database.ref(`schools/${schoolId}`);

    await Promise.all([
      schoolUserRef.child(`schools/${schoolId}`).set(true),
      schoolUserRef.child(`emailSettings/${schoolId}`).set({
        id: `${schoolId}`,
        analytics: "monthly",
      }),
      schoolRef.child(`team/${schoolUserId}`).set("Owner"),
    ]);

    /* All Districts */
    const schoolUserDistricts = schoolUser.districts
      ? Object.keys(schoolUser.districts)
      : [];
    let districtItems = await Promise.all(
      schoolUserDistricts.map(async (districtId) => {
        return await Database.ref(`districts/${districtId}`)
          .once("value")
          .then(function (snapshot) {
            const snapshotVal = snapshot.val();
            return snapshotVal;
          });
      })
    );

    const allDistrict = districtItems.find(
      (district) => district.defaultDistrict
    );
    if (allDistrict) {
      // Add school to all district
      let allDistrictRef = Database.ref(`districts/${allDistrict.id}`);

      let updatedDistrict = {
        ...allDistrict,
        schoolIds: {
          ...allDistrict.schoolIds,
          [schoolId]: true,
        },
      };

      /* Update all district */
      await allDistrictRef.set(updatedDistrict);
    } else {
      let newDistrict: any = {
        team: {
          [schoolUserId]: "Owner",
        },
        name: "All Schools",
        schoolIds: {
          [schoolId]: true,
        },
        defaultDistrict: true,
      };

      let districtRef = await Database.ref("districts").push(newDistrict);
      newDistrict.id = districtRef.key as string;

      /* Add id to db record */
      await districtRef.set(newDistrict);

      /* Update user record */
      await schoolUserRef.child(`districts/${newDistrict.id}`).set(true);
    }
  } catch (err) {
    console.log(err);
  }
};

export const createUserNotifcation = async (props) => {
  const {
    activityName,
    activityId,
    schoolId,
    responseId,
    userId,
    feedback,
    feedbackEmail,
    responses,
  } = props;
  /* Update activity response ref */
  const activityResponsesRef = Database.ref(
    `activityResponses/${schoolId}/${activityId}/${responseId}`
  );
  await activityResponsesRef.update({ feedback, feedbackEmail });

  /* Create notification */
  let notification: any = {
    activityName,
    feedback,
    feedbackEmail,
    responses,
    date: new Date().valueOf(),
  };

  const notificationRef = await Database.ref(`notifications/${userId}`).push(
    notification
  );
  (notification as any).id = notificationRef.key;

  await notificationRef.update({
    id: notification.id,
    status: "sending",
  });
};

/* Save Evaluation Translations */
export const updateCurrentEvaluationTranslationTargets = async () => {
  try {
    const evalAdminTranslateCall = Functions.httpsCallable(
      "evalAdminTranslate"
    );
    evalAdminTranslateCall();
  } catch (err) {
    console.error(err);
  }
};

/* Save Evaluation Translations */
export const updateSchoolEvalTranslationTargets = async (schoolId: any) => {
  try {
    const evalSchoolTranslateCall = Functions.httpsCallable(
      "evalSchoolTranslate"
    );
    evalSchoolTranslateCall({ schoolId });
  } catch (err) {
    console.error(err);
  }
};

/* Save scorecard Translations */
export const updateWeeklyScorecardTranslationTargets = async () => {
  try {
    const scorecardAdminTranslateCall = Functions.httpsCallable(
      "scorecardAdminTranslate"
    );
    scorecardAdminTranslateCall();
  } catch (err) {
    console.error(err);
  }
};

/* Save scorecard Translations */
export const updateSchoolScorecardTranslationTargets = async (
  schoolId: any
) => {
  try {
    const scorecardSchoolTranslateCall = Functions.httpsCallable(
      "scorecardSchoolTranslate"
    );
    scorecardSchoolTranslateCall({ schoolId });
  } catch (err) {
    console.error(err);
  }
};

export const readCurriculumFiles = (fileIds: any) => {
  return Promise.all(
    fileIds.map(async (fileId: any) => {
      const file = await Database.ref(`resources/${fileId}`)
        .once("value")
        .then((snapshot) => snapshot.val());
      return file;
    })
  );
};

export const updateGuardianNotes = async (
  notes: string,
  guardianId: string
) => {
  await Database.ref(`guardians/${guardianId}/notes`).set(notes);
};

export const getMonthlyData = async (
  sceneId: any,
  startDate: any,
  endDate: any
) => {
  /// Parents who engaged this month
  /// Responses to Activites
  /// Parent Uploads

  /* Handle time frame */
  let startAtDate: number;
  let endAtDate: number;
  startAtDate = startDate.valueOf();
  endAtDate = endDate.valueOf();

  const guardians = await guardiansRef
    .orderByChild(`schoolIds/${sceneId}`)
    .equalTo(true)
    .once("value")
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : null));
  const guardiansWithEngagement = guardians
    ? Object.values(guardians).filter((guardian: any) => {
        return guardian.onboardingComplete;
      })
    : [];

  /* Filter guardians if engagement time within start and end date */
  let guardiansCorrect: any = [];
  await Promise.all(
    guardiansWithEngagement.map(async (guardian: any) => {
      const userRecentAnalyticsRef = Database.ref(
        `analytics/userAnalytics/${guardian.uid}/recentAnalytics`
      );

      /* Last Activity */
      const activityInDate = await userRecentAnalyticsRef
        .orderByChild("time")
        .startAt(startAtDate)
        .endAt(endAtDate)
        .limitToLast(1)
        .once("value")
        .then(function (snapshot) {
          const snapshotVal = snapshot.exists() ? snapshot.val() : null;
          return snapshotVal;
        });

      if (activityInDate) {
        guardiansCorrect.push(guardian);
      }
    })
  );

  const priority = (el: string) => {
    const priorityList = [
      "Preschool",
      "Kindergarten",
      "1st grade",
      "2nd grade",
      "3rd grade",
      "4th grade",
      "5th grade",
      "6th grade",
      "7th grade",
      "8th grade",
      "9th grade",
      "10th grade",
      "11th grade",
      "12th grade",
    ];
    return priorityList.indexOf(el);
  };
  const parentNames = guardiansCorrect.map((guardian: any) => {
    const grades = guardian.gradeLevels
      .filter((school: any) => school.schoolId === sceneId)[0]
      .grades.map((grade: any) => grade);
    const sortedGrades = grades.sort(
      (a: any, b: any) => priority(a) - priority(b)
    );

    return {
      name: `${guardian.firstName} ${guardian.lastName}`,
      children: guardian.children
        ? `${guardian.children.map((i) => i.name).join(", ")}`
        : "",
      grades: sortedGrades.join(", "),
    };
  });

  // Activity responses
  let schoolActivityResponses: any = [];
  const schoolActivityResponsesObj = await Database.ref(
    `activityResponses/${sceneId}`
  )
    .once("value")
    .then((snapshot) => snapshot.val());
  if (schoolActivityResponsesObj) {
    const activityIds: any = Object.keys(schoolActivityResponsesObj);
    for (const [, activityId] of activityIds.entries()) {
      const activity = schoolActivityResponsesObj[activityId];
      let responses = Object.values(activity);

      responses = responses.filter((response: any) => {
        return response.time > startAtDate && response.time < endAtDate;
      });
      responses = responses.sort((a: any, b: any) => b.time - a.time);

      if (responses.length) {
        const activityName = await Database.ref(`activities/${activityId}/name`)
          .once("value")
          .then((snapshot) => snapshot.val());

        schoolActivityResponses.push({
          activityName,
          responses: responses,
        });
      }
    }
  }

  // Filter responses with upload
  let uploadResponses = schoolActivityResponses.filter((i: any) =>
    i.responses ? (i.responses[0].responses.url ? true : false) : false
  );
  let uploadsAgain: any = [];
  uploadResponses.map((act: any) => {
    act.responses.map((res: any) => {
      uploadsAgain.push({
        activityName: act.activityName,
        img: res.responses.url,
        name: `${res.userFirst} ${res.userLast}`,
        id: res.id,
        time: res.time,
      });
      return res;
    });
    return act;
  });
  uploadsAgain.sort((a: any, b: any) => b.time - a.time);

  schoolActivityResponses = schoolActivityResponses.filter((i: any) =>
    i.responses ? !i.responses[0].responses.url : true
  );

  return {
    parentNames,
    schoolActivityResponses,
    parentUploads: uploadsAgain,
  };
};

export const readSelectedUpload = async () => {
  const selectedUpload = await Database.ref("appSettings/selectedUpload")
    .once("value")
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : null));

  return selectedUpload;
};

export const setSelectedUpload = async (image: any) => {
  await Database.ref("appSettings/selectedUpload").set(image);
};

export const removeSelectedUpload = async () => {
  await Database.ref("appSettings/selectedUpload").remove();
};
