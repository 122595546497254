import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  sceneSelector,
  appSelector,
  userSelector,
  readSceneAction,
  ParentPartiesAPIs,
} from "../../core";
import {
  Grid,
  ListItem,
  List,
  Card,
  LinearProgress,
  CardContent,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Chart from "react-apexcharts";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function DistrictGuardiansAnalytics(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [analytics, setAnalytics] = useState(null);

  useEffect(() => {
    async function getGuardians(scene) {
      const schoolIds = scene
        ? scene.data
          ? Object.keys(scene.data.schoolIds)
          : []
        : [];
      const result = await ParentPartiesAPIs.districtGuardianAnalytics(
        schoolIds
      );

      setAnalytics(result);
    }

    if (props.scene) {
      getGuardians(props.scene);
    }
  }, [props.scene]);

  return !props.user ? null : props.scene ? (
    <>
      <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
        <Grid item md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              className="font-weight-bold display-4 mb-4"
              style={{ color: "rgb(43, 39, 60)" }}
            >
              District Guardian Analytics
            </div>
          </div>
        </Grid>
        <Grid item md={12}>
          <Grid container spacing={4} alignItems="stretch" direction={"row"}>
            <Grid item md={8}>
              <Card className="card-box">
                <div className="card-header">
                  <div className="card-header--title">
                    <h4 className="font-size-sm mb-0 py-2 font-weight-bold text-black">
                      Race/ethnicity
                    </h4>
                  </div>
                </div>
                <CardContent>
                  <div className="d-flex justify-content-center">
                    <Chart
                      options={{
                        dataLabels: {
                          enabled: false,
                        },
                        plotOptions: {
                          pie: {
                            donut: {
                              labels: {
                                show: true,
                                total: {
                                  show: true,
                                  showAlways: true,
                                  color: "#000",
                                  fontFamily: "Heebo",
                                },
                              },
                            },
                          },
                        },
                        colors: [
                          "#008FFB",
                          "#00E396",
                          "#FEB019",
                          "#3F51B5",
                          "#775DD0",
                          "#A5978B",
                          "#546E7A",
                          "#FF4560",
                        ],
                        labels: analytics
                          ? analytics.ethnicity
                            ? analytics.ethnicity.map((item) => item.label)
                            : []
                          : [],
                        legend: {
                          show: true,
                          position: "right",
                          formatter: function (seriesName, opts) {
                            return `${seriesName} (${
                              opts.w.globals.series[opts.seriesIndex]
                            })`;
                          },
                        },
                      }}
                      series={
                        analytics
                          ? analytics.ethnicity
                            ? analytics.ethnicity.map((item) => item.amount)
                            : []
                          : []
                      }
                      type="donut"
                      width="600"
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={12}>
              <Card className="card-box">
                <div className="card-header">
                  <div className="card-header--title">
                    <h4 className="font-size-sm mb-0 py-2 font-weight-bold text-black">
                      Grade levels
                    </h4>
                  </div>
                </div>
                <CardContent>
                  <div>
                    <Chart
                      options={{
                        chart: {
                          toolbar: {
                            show: false,
                          },
                          sparkline: {
                            enabled: false,
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        colors: ["#1A9A9A"],
                        grid: {
                          strokeDashArray: "5",
                          borderColor: "rgba(125, 138, 156, 0.3)",
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          colors: ["transparent"],
                        },
                        legend: {
                          show: false,
                        },
                        labels: analytics
                          ? analytics.gradeLevel
                            ? analytics.gradeLevel.map((item) => item.label)
                            : []
                          : [],
                      }}
                      series={
                        analytics
                          ? analytics.gradeLevel
                            ? [
                                {
                                  name: "Guardians",
                                  data: analytics.gradeLevel.map(
                                    (item) => item.amount
                                  ),
                                },
                              ]
                            : []
                          : []
                      }
                      type="bar"
                      height={280}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={12}>
              <Card className="card-box">
                <div className="card-header">
                  <div className="card-header--title">
                    <h4 className="font-size-sm mb-0 py-2 font-weight-bold text-black">
                      Language
                    </h4>
                  </div>
                </div>
                <CardContent>
                  <div>
                    <Chart
                      options={{
                        chart: {
                          toolbar: {
                            show: false,
                          },
                          sparkline: {
                            enabled: false,
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        colors: ["#1A9A9A"],
                        grid: {
                          strokeDashArray: "5",
                          borderColor: "rgba(125, 138, 156, 0.3)",
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          colors: ["transparent"],
                        },
                        legend: {
                          show: false,
                        },
                        labels: analytics
                          ? analytics.language
                            ? analytics.language.map((item) => item.label)
                            : []
                          : [],
                      }}
                      series={
                        analytics
                          ? analytics.language
                            ? [
                                {
                                  name: "Guardians",
                                  data: analytics.language.map(
                                    (item) => item.amount
                                  ),
                                },
                              ]
                            : []
                          : []
                      }
                      type="bar"
                      height={280}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  readSceneAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistrictGuardiansAnalytics);
