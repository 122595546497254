import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  sceneSelector,
  appSelector,
  userSelector,
  readSceneAction,
  ParentPartiesAPIs,
} from "../../core";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, ListItem, List, Button, Tooltip, Card } from "@material-ui/core";
import AGGrid from "../Grid";
import { useLocation, NavLink } from "react-router-dom";
import firebaseDynamicLink from "../../utils/firebaseDynamicLinks";
import QRCode from "qrcode.react";
import { saveAs } from "file-saver";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { Document, pdf } from "@react-pdf/renderer";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import OpenSans from "../../assets/reportFonts/OpenSans-Light.ttf";
import Parent_Parties_Horz_Navy from "../../assets/Parent_Parties_Horz_Navy.png";

// Responsive //
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CircleLoader } from "react-spinners";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: OpenSans,
      fontWeight: 300,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: "20",
  },
  mainContainer: {
    padding: "0 20 0 20",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "300px",
    textAlign: "center",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});

function dateComparator(date1, date2) {
  if (date1 === null && date2 === null) {
    return 0;
  }
  if (date1 === null) {
    return -1;
  }
  if (date2 === null) {
    return 1;
  }

  return date1 - date2;
}

function SchoolResources(props) {
  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const sceneType = params[2];
  const sceneId = params[3];

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [initialLoad, setInitialLoad] = useState(true);
  const [files, setFiles] = useState([]);
  const [QRLink, setQRLink] = useState("");
  const [pdfLoader, setPDFLoader] = useState(false);

  const keyRef = useRef(null);
  const [copied, setCopied] = useState(false);
  function copyToClipboard(e) {
    keyRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopied(true);
  }

  useEffect(() => {
    async function getFiles() {
      const files = await ParentPartiesAPIs.readSchoolFiles();
      const arrayOfFiles = files
        ? Object.values(files).filter((i) => i.premium === false)
        : [];
      setFiles(arrayOfFiles);

      if (props.scene) {
        const generatedLink = await firebaseDynamicLink({
          dynamicLinkInfo: {
            domainUriPrefix: `https://parentparties.page.link`,
            link: `https://www.parentpartiesengage.com/school/${props.scene.id}`,
            iosInfo: {
              iosBundleId: "com.csmatrix.parentparties",
              iosAppStoreId: "1588012431",
            },
            androidInfo: {
              androidPackageName: "com.csmatrix.parentparties",
            },
            navigationInfo: {
              enableForcedRedirect: true,
            },
          },
          suffix: {
            option: "SHORT",
          },
        });

        setQRLink(generatedLink);
      }
      setInitialLoad(false);
    }

    getFiles();
  }, [props.scene]);

  const downloadQR = () => {
    const canvas = document.getElementById(`QRCode-${props.scene.id}`);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `PPE_QR_Code.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleQRCodeFlyer = async () => {
    setPDFLoader(true);
    const canvas = document.getElementById(`QRCode-${sceneId}`);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    const doc = (
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={styles.mainContainer}>
              <View style={styles.imageContainer}>
                <Image
                  style={{ width: 200, height: 150 }}
                  src={Parent_Parties_Horz_Navy}
                />
              </View>
              <View
                style={{
                  marginTop: "15",
                  width: "100%",
                }}
              >
                <Text style={{ fontSize: "18pt", textAlign: "center" }}>
                  Parents and Guardians:
                </Text>
              </View>
              <View
                style={{
                  paddingTop: "5",
                  marginBottom: "30",
                  width: "100%",
                }}
              >
                <Text
                  style={{
                    fontSize: "18pt",
                    textDecoration: "underline",
                    textAlign: "center",
                  }}
                >
                  Download our new app!
                </Text>
              </View>
              <View style={{ paddingBottom: 20, width: "100%" }}>
                <Text style={{ textAlign: "center", fontSize: "15pt" }}>
                  Engage with the school through in-app activities, earn points,
                  and compete with other parents for prizes!
                </Text>
              </View>
              <View
                style={{
                  paddingTop: 10,
                  fontSize: "18pt",
                  marginBottom: "25",
                  width: "100%",
                }}
              >
                <Text>Step 1: Scan the QR Code</Text>
              </View>
              <View style={styles.imageContainer}>
                <Image style={{ width: 150, height: 150 }} src={pngUrl} />
              </View>
              <View
                style={{
                  fontSize: "18pt",
                  marginBottom: "40",
                  marginTop: "30",
                  width: "100%",
                }}
              >
                <Text>Step 2: Install the app on your phone</Text>
              </View>
              <View
                style={{
                  fontSize: "18pt",
                  marginBottom: "40",
                  width: "100%",
                }}
              >
                <Text>Step 3: Create your account</Text>
              </View>
              <View
                style={{
                  fontSize: "18pt",
                  marginBottom: "40",
                  width: "100%",
                }}
              >
                <Text>Step 4: Start earning points!</Text>
              </View>
            </View>

            <View style={styles.mainContainer}>
              <View style={styles.imageContainer}>
                <Image
                  style={{ width: 200, height: 150 }}
                  src={Parent_Parties_Horz_Navy}
                />
              </View>
              <View
                style={{
                  marginTop: "15",
                  width: "100%",
                }}
              >
                <Text style={{ fontSize: "18pt", textAlign: "center" }}>
                  Parents and Guardians:
                </Text>
              </View>
              <View
                style={{
                  paddingTop: "5",
                  marginBottom: "30",
                  width: "100%",
                }}
              >
                <Text
                  style={{
                    fontSize: "18pt",
                    textDecoration: "underline",
                    textAlign: "center",
                  }}
                >
                  Download our new app!
                </Text>
              </View>
              <View style={{ paddingBottom: 20, width: "100%" }}>
                <Text style={{ textAlign: "center", fontSize: "15pt" }}>
                  Engage with the school through in-app activities, earn points,
                  and compete with other parents for prizes!
                </Text>
              </View>
              <View
                style={{
                  paddingTop: 10,
                  fontSize: "18pt",
                  marginBottom: "25",
                  width: "100%",
                }}
              >
                <Text>Step 1: Scan the QR Code</Text>
              </View>
              <View style={styles.imageContainer}>
                <Image style={{ width: 150, height: 150 }} src={pngUrl} />
              </View>
              <View
                style={{
                  fontSize: "18pt",
                  marginBottom: "40",
                  marginTop: "30",
                  width: "100%",
                }}
              >
                <Text>Step 2: Install the app on your phone</Text>
              </View>
              <View
                style={{
                  fontSize: "18pt",
                  marginBottom: "40",
                  width: "100%",
                }}
              >
                <Text>Step 3: Create your account</Text>
              </View>
              <View
                style={{
                  fontSize: "18pt",
                  marginBottom: "40",
                  width: "100%",
                }}
              >
                <Text>Step 4: Start earning points!</Text>
              </View>
            </View>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={styles.mainContainer}>
              <View style={styles.imageContainer}>
                <Image
                  style={{ width: 200, height: 150 }}
                  src={Parent_Parties_Horz_Navy}
                />
              </View>
              <View
                style={{
                  marginTop: "15",
                  width: "100%",
                }}
              >
                <Text style={{ fontSize: "18pt", textAlign: "center" }}>
                  Padres:
                </Text>
              </View>
              <View
                style={{
                  paddingTop: "5",
                  marginBottom: "30",
                  width: "100%",
                }}
              >
                <Text
                  style={{
                    fontSize: "18pt",
                    textDecoration: "underline",
                    textAlign: "center",
                  }}
                >
                  ¡Descarga nuestra nueva app!
                </Text>
              </View>
              <View style={{ paddingBottom: 20, width: "100%" }}>
                <Text style={{ textAlign: "center", fontSize: "15pt" }}>
                  ¡Conéctese con el colegio a través de las actividades de la
                  aplicación, gane puntos y compita con otros padres por
                  premios!
                </Text>
              </View>
              <View
                style={{
                  paddingTop: 10,
                  fontSize: "18pt",
                  marginBottom: "25",
                  width: "100%",
                }}
              >
                <Text>Paso 1: Escanea el código QR</Text>
              </View>
              <View style={styles.imageContainer}>
                <Image style={{ width: 150, height: 150 }} src={pngUrl} />
              </View>
              <View
                style={{
                  fontSize: "18pt",
                  marginBottom: "40",
                  marginTop: "30",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Text style={{ width: "250", textAlign: "center" }}>
                  Paso 2: Instala la aplicación Parent Parties ENGAGE en tu
                  teléfono
                </Text>
              </View>
              <View
                style={{
                  fontSize: "18pt",
                  marginBottom: "40",
                  width: "100%",
                }}
              >
                <Text>Paso 3: Crea tu cuenta</Text>
              </View>
              <View
                style={{
                  fontSize: "18pt",
                  marginBottom: "40",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Text style={{ width: "225", textAlign: "center" }}>
                  Paso 4: ¡Empieza a ganar puntos!
                </Text>
              </View>
            </View>

            <View style={styles.mainContainer}>
              <View style={styles.imageContainer}>
                <Image
                  style={{ width: 200, height: 150 }}
                  src={Parent_Parties_Horz_Navy}
                />
              </View>
              <View
                style={{
                  marginTop: "15",
                  width: "100%",
                }}
              >
                <Text style={{ fontSize: "18pt", textAlign: "center" }}>
                  Padres:
                </Text>
              </View>
              <View
                style={{
                  paddingTop: "5",
                  marginBottom: "30",
                  width: "100%",
                }}
              >
                <Text
                  style={{
                    fontSize: "18pt",
                    textDecoration: "underline",
                    textAlign: "center",
                  }}
                >
                  ¡Descarga nuestra nueva app!
                </Text>
              </View>
              <View style={{ paddingBottom: 20, width: "100%" }}>
                <Text style={{ textAlign: "center", fontSize: "15pt" }}>
                  ¡Conéctese con el colegio a través de las actividades de la
                  aplicación, gane puntos y compita con otros padres por
                  premios!
                </Text>
              </View>
              <View
                style={{
                  paddingTop: 10,
                  fontSize: "18pt",
                  marginBottom: "25",
                  width: "100%",
                }}
              >
                <Text>Paso 1: Escanea el código QR</Text>
              </View>
              <View style={styles.imageContainer}>
                <Image style={{ width: 150, height: 150 }} src={pngUrl} />
              </View>
              <View
                style={{
                  fontSize: "18pt",
                  marginBottom: "40",
                  marginTop: "30",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Text style={{ width: "250", textAlign: "center" }}>
                  Paso 2: Instala la aplicación Parent Parties ENGAGE en tu
                  teléfono
                </Text>
              </View>
              <View
                style={{
                  fontSize: "18pt",
                  marginBottom: "40",
                  width: "100%",
                }}
              >
                <Text>Paso 3: Crea tu cuenta</Text>
              </View>
              <View
                style={{
                  fontSize: "18pt",
                  marginBottom: "40",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Text style={{ width: "225", textAlign: "center" }}>
                  Paso 4: ¡Empieza a ganar puntos!
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );

    try {
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, `PPE_QR_Flyer.pdf`);
    } catch (err) {
      console.log("err", err);
    }
    setPDFLoader(false);
  };

  return !props.user ? null : props.scene ? (
    initialLoad || props.app.isLoading ? (
      <Grid container className="my-spacing-6">
        <Grid item xs={12}>
          <div className="d-flex flex-row justify-content-center">
            <CircleLoader
              className="d-flex align-self-center"
              color={"#18a9a9"}
              loading={true}
              size={40}
            />
          </div>
        </Grid>
      </Grid>
    ) : (
      <>
        <Grid container spacing={0} style={{ marginBottom: "6rem" }}>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                className="font-weight-bold display-4"
                style={{ color: "rgb(43, 39, 60)" }}
              >
                Get Started
              </div>
            </div>
          </Grid>
          <Grid item md={12} xl={12}>
            {mobile ? null : (
              <List
                component="div"
                className="nav-line-alt nav-line d-flex align-items-center mt-3"
              >
                <ListItem button selected className="px-2">
                  <span className="font-size-sm">{"Onboard Parents"}</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to={`/team/${sceneType}/${sceneId}`}
                  className="pr-2"
                >
                  <span className="font-size-sm">{"Admin Team"}</span>
                  <div className="divider" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to={`/more-info/${sceneType}/${sceneId}`}
                  className="pr-2"
                >
                  <span className="font-size-sm">{"Contact Info"}</span>
                  <div className="divider" />
                </ListItem>
              </List>
            )}
          </Grid>
          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingBottom: 10,
              }}
              className="mt-4 pt-4"
            >
              <div
                style={{
                  color: "#000",
                  fontSize: "16px",
                  lineHeight: "16px",
                  fontWeight: "bold",
                }}
              >
                Mobile App Links & QR Code
              </div>
            </div>
            <div className="mt-1">
              <small className="text-black-50">
                Use the following link or scan this QR Code to download the
                Parent Parties Engage app and be directed to your school.
              </small>
            </div>
            <div className="d-flex flex-row align-items-center">
              <Card
                className="py-2 mr-4 mt-4 px-4"
                style={{
                  borderRadius: "0.25rem",
                  border: "1px solid rgba(0,0,0,.1)",
                }}
              >
                <div>
                  <div className="d-block">
                    <label className="font-weight-bold font-size-sm">
                      Link to App
                    </label>
                  </div>
                  {props.scene ? (
                    props.scene.data ? (
                      document.queryCommandSupported("copy") ? (
                        <Tooltip
                          TransitionProps={{
                            onExited: () => setCopied(false),
                          }}
                          title={
                            copied ? (
                              <div className="d-flex flex-row align-items-center">
                                <div>
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="text-success font-size-sm mr-2"
                                  />
                                </div>
                                <div className="font-size-sm">Copied</div>
                              </div>
                            ) : (
                              <div className="font-size-sm">Click to copy</div>
                            )
                          }
                          placement="bottom"
                          arrow={true}
                        >
                          <div
                            className="font-size-sm text-black"
                            style={{ display: "inline-block" }}
                            onClick={copyToClipboard}
                          >
                            {QRLink}
                          </div>
                        </Tooltip>
                      ) : (
                        <div
                          className="font-size-sm text-black"
                          style={{ display: "inline-block" }}
                        >
                          {QRLink}
                        </div>
                      )
                    ) : null
                  ) : null}
                  {document.queryCommandSupported("copy") ? (
                    <>
                      <textarea
                        readOnly
                        style={{
                          position: "absolute",
                          opacity: 0,
                          width: 1,
                          height: 1,
                          bottom: -5000,
                        }}
                        ref={keyRef}
                        value={QRLink}
                      />
                    </>
                  ) : null}
                </div>
              </Card>

              <Button
                variant="text"
                className="mr-4 card card-box d-block text-left text-primary d-flex justify-content-center py-3 bg-primary mt-3"
                onClick={(e) => {
                  e.preventDefault();
                  downloadQR();
                }}
              >
                <div className="font-weight-bold font-size-sm text-white text-left mb-1 d-flex flex-row align-items-center">
                  QR Code
                  <FontAwesomeIcon
                    icon={["fas", "download"]}
                    className="ml-3"
                    style={{ fontSize: "14px" }}
                  />
                </div>
              </Button>
              <Button
                variant="text"
                className="card card-box d-block text-left text-primary d-flex justify-content-center py-3 bg-primary mt-3"
                onClick={handleQRCodeFlyer}
              >
                <div className="font-weight-bold font-size-sm text-white text-left mb-1 d-flex flex-row align-items-center">
                  <>
                    QR Code Flyer
                    {pdfLoader ? (
                      <div className="d-flex align-self-center ml-3">
                        <CircleLoader color={"#fff"} loading={true} size={20} />
                      </div>
                    ) : (
                      <FontAwesomeIcon
                        icon={["fas", "download"]}
                        className="ml-3"
                        style={{ fontSize: "14px" }}
                      />
                    )}
                  </>
                </div>
              </Button>
            </div>
            {props.scene ? (
              props.scene.data ? (
                <div style={{ display: "none" }}>
                  <QRCode
                    value={QRLink}
                    id={`QRCode-${props.scene.id}`}
                    size={200}
                  />
                </div>
              ) : null
            ) : null}
          </Grid>
          <Grid item md={12}>
            <AGGrid
              smallTitle={true}
              titleGrid={
                <div className="mt-3">
                  <div>Parent Onboarding Resources</div>
                  <div className="mt-1">
                    <small className="text-black-50">
                      Files provided by Parent Parties Engage for marketing
                      purposes.
                    </small>
                  </div>
                </div>
              }
              search={true}
              statusBar={false}
              rowData={files}
              columnDefs={[
                {
                  headerName: "File Name",
                  field: "name",
                  sortable: true,
                  filter: true,
                },
                {
                  headerName: "Upload Date",
                  field: "time",
                  sortable: true,
                  filter: "agDateColumnFilter",
                  valueFormatter: function (params) {
                    if (params.data.time) {
                      return moment(params.data.time).format("L");
                    } else {
                      return null;
                    }
                  },
                  comparator: dateComparator,
                },
                {
                  width: 280,
                  suppressSizeToFit: true,
                  field: "actions",
                  headerName: "Actions",
                  cellRendererFramework: (params) => (
                    <div className="d-flex flex-row align-items-center">
                      <a
                        href={params.data.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mr-2 MuiButtonBase-root MuiButton-root MuiButton-contained bg-dark shadow-none MuiButton-containedSizeSmall MuiButton-sizeSmall"
                        tabIndex="0"
                        type="button"
                      >
                        <span className="MuiButton-label">
                          <span
                            className="btn-wrapper--label text-white"
                            style={{ fontSize: "12px" }}
                          >
                            Preview
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </a>
                    </div>
                  ),
                  cellClass: "agGridCellCenter",
                },
              ]}
            />
          </Grid>
        </Grid>
      </>
    )
  ) : null;
}

const mapStateToProps = (state) => ({
  app: appSelector(state),
  scene: sceneSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  readSceneAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolResources);
